import React, { useEffect, useMemo } from 'react';
import { PriceColumn } from '@idearoom/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { setHighlightedCell, setPricingBaseDataBranch, setSelectedPricingSheetId } from '../ducks/pricingSlice';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { unknownGroup } from '../constants/Group';
import { LocalStorage } from '../constants/LocalStorage';
import { getClientIdFromClientSupplier, getVendorFromClientId, isCarportView } from '../utils/clientIdUtils';
import { notifyOfDataChange } from '../utils/pricingUtils';
import { ClientDataType } from '../constants/ClientDataType';
import { setClientDataType } from '../ducks/clientDataSlice';
import { PricingSheetView } from './PricingSheetView';
import { PricingPublishResultDialog } from './PricingPublishResultDialog';
import { PricingBasePreviewDialog } from './PricingBasePreviewDialog';
import { PricingBaseAddSizeDialog } from './PricingBaseAddSizeDialog';
import { PricingPublishDialog } from './PricingPublishDialog';
import { I18nKeys } from '../constants/I18nKeys';
import { PricingSheetRegion, Region } from '../types/Region';
import { UserPreference } from '../constants/User';
import { GridViewType } from '../constants/GridViewType';
import { useDeleteBranchMutation } from '../services/clientDataApi';
import { PricingSheetPriceData } from '../types/PricingSheetPrice';

export const PricingBase: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [errorLoadingPricingSheets, setErrorLoadingPricingSheets] = React.useState(false);

  const viewerClientId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId || '',
  );
  const clientId = getClientIdFromClientSupplier(viewerClientId);

  const {
    clientData: { clientId: clientDataClientId, clientDataType, isCreatingBranch },
    pricing: {
      base: { pricingDataBranch = ClientDataBranch.Main, selectedPricingSheetId },
    },
    currentUser: {
      group: { groupId } = unknownGroup,
      preferences: { [UserPreference.PricingBasePreferences]: pricingBasePreferences = { gridViewType: {} } } = {},
    },
  } = useAppSelector((state) => state);
  let { [groupId]: { [viewerClientId]: clientGridViewType } = {} } = pricingBasePreferences.gridViewType;
  if (!clientGridViewType) {
    clientGridViewType = isCarportView(clientId) ? GridViewType.Grid : GridViewType.List;
  }

  if (clientDataType !== ClientDataType.Supplier) {
    dispatch(setClientDataType(ClientDataType.Supplier));
  }

  const {
    isErrorPricingBaseSheets,
    isFetchingPricingBaseSheets,
    isLoadingPricingBaseSheets,
    pricingBaseSheets,
    pricingBranchMergeCommit,
    isLoadingBranches,
    activeBranches,
    supplierRegions = [],
    cellMetadata,
    cellMetadataDiff,
    pricingBaseDiffs,
  } = usePricingRepo({
    useSupplierRegions: true,
    usePricingSheetBase: true,
    usePricingBranchMergeCommit: true,
    useBranches: true,
    useCellMetadata: true,
    useCellMetadataDiff: true,
    usePricingBaseDiffs: true,
  });
  const pricingBranchExists = activeBranches.find((branch) => branch.branchType === ClientDataBranch.Pricing);
  const selectedPricingSheet = pricingBaseSheets.find((sheet) => sheet.id === selectedPricingSheetId);

  useEffect(() => {
    if (clientId && clientId !== clientDataClientId) {
      dispatch(setPricingBaseDataBranch(ClientDataBranch.Main));
      dispatch(setHighlightedCell(undefined));
      dispatch(setSelectedPricingSheetId(undefined));
      setErrorLoadingPricingSheets(false);
    }
  }, [clientId, clientDataClientId, dispatch]);

  useEffect(() => {
    notifyOfDataChange({
      groupId,
      clientId,
      latestCommit: pricingBranchMergeCommit,
      key: LocalStorage.LastPricingBaseMergeCommit,
      dispatch,
      t,
    });
  }, [pricingBranchMergeCommit, dispatch, t, groupId, clientId]);

  const [, { isLoading: isDeletingBranch = false }] = useDeleteBranchMutation({
    fixedCacheKey: 'revert',
  });

  useEffect(() => {
    if (isLoadingBranches || isCreatingBranch || isDeletingBranch) return;
    const existsAndFromClientId =
      pricingBranchExists && pricingBranchExists.name.includes(getVendorFromClientId(clientId));
    if (existsAndFromClientId && pricingDataBranch !== ClientDataBranch.Pricing) {
      dispatch(setPricingBaseDataBranch(ClientDataBranch.Pricing));
    }
    if (!existsAndFromClientId && pricingDataBranch !== ClientDataBranch.Main) {
      dispatch(setPricingBaseDataBranch(ClientDataBranch.Main));
    }
  }, [
    pricingBranchExists,
    clientId,
    pricingDataBranch,
    dispatch,
    isLoadingBranches,
    isCreatingBranch,
    isDeletingBranch,
  ]);

  useEffect(() => {
    if (!selectedPricingSheetId && pricingBaseSheets?.length > 0) {
      const [sheet] = pricingBaseSheets;
      dispatch(setSelectedPricingSheetId(sheet.id));
    }
  }, [selectedPricingSheetId, pricingBaseSheets, dispatch]);

  if (!errorLoadingPricingSheets && !isLoadingPricingBaseSheets && isErrorPricingBaseSheets) {
    setErrorLoadingPricingSheets(true);
  }

  const [regions, setRegions] = React.useState<PricingSheetRegion[]>([]);
  useMemo(() => {
    const newRegions: { [key: string]: Region } = {};
    const newRegionsIncluded: { [key: string]: boolean } = {};

    for (let i = 0; i < supplierRegions.length; i += 1) {
      const region = supplierRegions[i];
      const regionPriceColumn = newRegions[region.priceColumn];
      if (regionPriceColumn) {
        if (Number(region.priority || 0) < Number(regionPriceColumn.priority || 0)) {
          newRegions[region.priceColumn] = region;
          newRegionsIncluded[region.priceColumn] = regions.some(
            (r) =>
              r.label === region.label &&
              r.priceColumn.default === region.priceColumn &&
              r.supplierKey === region.supplierKey,
          );
        }
      } else {
        newRegions[region.priceColumn] = region;
        newRegionsIncluded[region.priceColumn] = regions.some(
          (r) =>
            r.label === region.label &&
            r.priceColumn.default === region.priceColumn &&
            r.supplierKey === region.supplierKey,
        );
      }
    }

    if (Object.values(newRegionsIncluded).includes(false)) {
      setRegions(
        Object.values(newRegions)
          .sort((a: Region, b: Region) => {
            if (a.priceColumn === PriceColumn.price) return -1;
            if (b.priceColumn === PriceColumn.price) return 1;
            return a.label.localeCompare(b.label);
          })
          .map(({ priceColumn, ...region }) => ({
            ...region,
            priceColumn: {
              default: `${priceColumn}` as keyof PricingSheetPriceData,
            },
          })),
      );
    }
  }, [supplierRegions, regions, setRegions]);

  return (
    <>
      <PricingSheetView
        gridViewType={clientGridViewType}
        selectedPricingSheetId={selectedPricingSheetId}
        selectedPricingSheet={selectedPricingSheet}
        pricingSheets={pricingBaseSheets}
        errorLoadingPricingSheets={errorLoadingPricingSheets}
        isFetchingPricingSheets={isFetchingPricingBaseSheets}
        isLoadingPricingSheets={isLoadingPricingBaseSheets}
        isLoadingPricingSheetPrices={isLoadingPricingBaseSheets}
        regions={regions}
        defaultPriceColumn={{ default: PriceColumn.price }}
        cellMetadata={cellMetadata}
        cellMetadataDiff={cellMetadataDiff}
        pricingDiffs={pricingBaseDiffs}
      />

      <PricingBaseAddSizeDialog />
      <PricingBasePreviewDialog />
      <PricingPublishDialog
        pricingBranch={ClientDataBranch.Pricing}
        title={I18nKeys.PricingBasePublishDialogTitle}
        content={I18nKeys.PricingBasePublishDialogText}
      />
      <PricingPublishResultDialog />
    </>
  );
};
