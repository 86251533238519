/* eslint-disable no-param-reassign */
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { PricingSurchargeConditionType, PricingSurchargeVaryCondition } from '@idearoom/types';
import { GridRowId } from '@mui/x-data-grid-premium';
import { PricingState } from '../types/PricingState';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { PublishingResult } from '../types/ClientData';
import { UpdateClientDataMetadata, UpdateClientDataRow } from './clientDataSlice';
import { ComponentCategoryKey, SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import { TableData } from '../types/DataGrid';
import { PricingSheet } from '../types/PricingSheet';
import {
  defaultPricingSurchargeErrors,
  SurchargeCalculationProperty,
  SurchargeRuleProperty,
  SurchargeUpdateProperty,
} from '../constants/PricingSurcharge';
import { PricingSurchargeError } from '../types/PricingSurcharge';
import { ComponentFormData, PriceByQuantity } from '../constants/PricingClientUpdate';

export interface NewSurchargeRule {
  isNew: boolean;
}

export interface DeleteSurchargeRule {
  id: number;
  isDelete: boolean;
}

export type UpdateSurchargeRule =
  // Updating rule's calculation
  | {
      id: number;
      property: SurchargeRuleProperty.Calculation;
      value: {
        property: SurchargeCalculationProperty;
        value: number;
      };
    }
  | {
      id?: number;
      property: SurchargeRuleProperty.Conditions;
      value: // Removing a condition type from a rules (or all rules if no ID is provided)
      | {
            type: PricingSurchargeConditionType;
            isDelete: boolean;
          }
        // Adding a condition type to a rules (or all rules if no ID is provided)
        | {
            type: PricingSurchargeConditionType;
            isNew: boolean;
          }
        // Updating one of a rule's conditions by type
        | {
            type: PricingSurchargeConditionType;
            value: string[] | { minimum: number; maximum: number };
          };
    };

export type EditSurchargeRule = NewSurchargeRule | UpdateSurchargeRule | DeleteSurchargeRule;

export type CommonSurchargeUpdateProperty =
  | SurchargeUpdateProperty.Type
  | SurchargeUpdateProperty.SupplierKey
  | SurchargeUpdateProperty.SupplierSurchargeOption
  | SurchargeUpdateProperty.Categories
  | SurchargeUpdateProperty.IsSubtotal
  | SurchargeUpdateProperty.Label
  | SurchargeUpdateProperty.IsTaxable
  | SurchargeUpdateProperty.AffectsDeposit;
export type UpdateSurchargeProperty =
  | {
      property: CommonSurchargeUpdateProperty;
      value: string | boolean | number;
    }
  | {
      property: SurchargeUpdateProperty.Calculation;
      value: {
        property: SurchargeCalculationProperty;
        value: number;
      };
    }
  | {
      property: SurchargeUpdateProperty.Rules;
      value: EditSurchargeRule;
    };

const initialState: PricingState = {
  highlightedCell: undefined,
  publishMergeResult: undefined,
  base: {
    pricingDataBranch: undefined,
    selectedPricingSheetId: undefined,
    pricingSheets: undefined,
  },
  component: {
    pricingDataBranch: undefined,
    selectedCategoryKey: undefined,
    selectedComponentId: undefined,
  },
  sizeBased: {
    pricingDataBranch: undefined,
    selectedCategoryKey: undefined,
    selectedPricingSheetId: undefined,
    priceByQuantity: undefined,
  },
  surcharge: {
    supplierKey: undefined,
    conditionOptions: [],
    surchargeErrors: defaultPricingSurchargeErrors,
  },
  fetchingEndpoints: {},
};

export const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setPricingBaseDataBranch(state, action: PayloadAction<ClientDataBranch | undefined>) {
      state.base.pricingDataBranch = action.payload;
    },
    setHighlightedCell(state, action: PayloadAction<{ region: string; colId?: string; rowId?: string } | undefined>) {
      const { payload } = action;
      state.highlightedCell = payload;
    },
    setPricingPublishMergeResult(
      state,
      action: PayloadAction<{
        data: PublishingResult | undefined;
        error: any | undefined;
        isSuccess: boolean;
      }>,
    ) {
      state.publishMergeResult = action.payload;
    },
    setSelectedPricingSheetId(state, action: PayloadAction<string | undefined>) {
      state.base.selectedPricingSheetId = action.payload;
    },
    setPricingComponentDataBranch(state, action: PayloadAction<ClientDataBranch | undefined>) {
      state.component.pricingDataBranch = action.payload;
    },
    setSelectedComponentCategoryKey(state, action: PayloadAction<ComponentCategoryKey | undefined>) {
      state.component.selectedCategoryKey = action.payload;
    },
    setSelectedComponentId(state, action: PayloadAction<GridRowId | undefined>) {
      state.component.selectedComponentId = action.payload;
    },
    setPricingSizeBasedDataBranch(state, action: PayloadAction<ClientDataBranch | undefined>) {
      state.sizeBased.pricingDataBranch = action.payload;
    },
    setSelectedSizeBasedCategoryKey(state, action: PayloadAction<SizeBasedCategoryKey | undefined>) {
      state.sizeBased.selectedCategoryKey = action.payload;
      state.sizeBased.priceByQuantity = undefined;
    },
    setSelectedSizeBasedPricingSheetId(state, action: PayloadAction<string | undefined>) {
      state.sizeBased.selectedPricingSheetId = action.payload;
      state.sizeBased.priceByQuantity = undefined;
    },
    setPriceByQuantity(state, action: PayloadAction<PriceByQuantity | undefined>) {
      state.sizeBased.priceByQuantity = action.payload;
    },
    addFetchingEndpoint(state, action: PayloadAction<{ promise: Promise<void>; endpointCacheKey: string }>) {
      state.fetchingEndpoints[action.payload.endpointCacheKey] = action.payload.promise;
    },
    removeFetchingEndpoint(state, action: PayloadAction<string>) {
      state.fetchingEndpoints[action.payload] = undefined;
    },
    setSurchargeConditionOptions: (state, action: PayloadAction<PricingSurchargeVaryCondition[]>) => {
      state.surcharge.conditionOptions = action.payload;
    },
    setSurchargeSupplierKey(state, action: PayloadAction<string | undefined>) {
      state.surcharge.supplierKey = action.payload;
    },
    addSurchargeError(state, action: PayloadAction<PricingSurchargeError>) {
      state.surcharge.surchargeErrors.push(action.payload);
    },
    setSurchargeErrors(state, action: PayloadAction<PricingSurchargeError[]>) {
      state.surcharge.surchargeErrors = action.payload;
    },
    clearSurchargeErrors(state) {
      state.surcharge.surchargeErrors = defaultPricingSurchargeErrors;
    },
  },
});

export const {
  setPricingBaseDataBranch,
  setHighlightedCell,
  setPricingPublishMergeResult,
  setSelectedPricingSheetId,
  setPricingComponentDataBranch,
  setSelectedComponentCategoryKey,
  setSelectedComponentId,
  setPricingSizeBasedDataBranch,
  setSelectedSizeBasedCategoryKey,
  setSelectedSizeBasedPricingSheetId,
  setPriceByQuantity,
  addFetchingEndpoint,
  removeFetchingEndpoint,
  setSurchargeSupplierKey,
  setSurchargeConditionOptions,
  addSurchargeError,
  setSurchargeErrors,
  clearSurchargeErrors,
} = pricingSlice.actions;

export const updatePricingComponentRows = createAction<ComponentFormData[]>('pricing/updatePricingComponentRows');

export const updateSurchargeProperty = createAction<UpdateSurchargeProperty[]>('pricing/updateSurchargeProperty');

export const updatePricingSheetRows = createAction<UpdateClientDataRow[]>('pricing/updatePricingSheetRows');

export const updatePricingMetadata = createAction<UpdateClientDataMetadata[]>('pricing/updatePricingMetadata');

export const addPricingBaseRow = createAction<{ rows: TableData[]; selectedPricingSheet: PricingSheet }>(
  'pricing/addPricingBaseRow',
);

export const removePricingBaseRows = createAction<{
  rows: TableData[];
  selectedPricingSheet: PricingSheet;
}>('pricing/removePricingBaseRows');
