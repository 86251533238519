import { Select, MenuItem, SelectChangeEvent, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState } from '../types/AppState';
import { useAppDispatch, useAppSelector } from '../hooks';
import { unknownGroup } from '../constants/Group';
import { setClientId, setClientDataType, setSelectedTable } from '../ducks/clientDataSlice';
import {
  mapConfiguratorToClientId,
  mapClientAndDataTypeToClientDataId,
  getClientAndDataTypeFromClientDataId,
  getVendorFromClientId,
} from '../utils/clientIdUtils';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { ClientDataType } from '../constants/ClientDataType';
import { getClientDataIdFromUrl, setClientDataIdInUrl } from '../utils/clientDataUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { ToastMessageType } from '../constants/Viewer';
import { setSelectedClientId, setSelectedTabId, setToastMessage } from '../ducks/viewerSlice';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    '& > svg': {
      color: theme.palette.primary.contrastText,
    },
    margin: '0px 0px 0px 5px',
    alignSelf: 'center',
  },
  selectDisplay: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    fontSize: '20px',
    padding: '5px',
    margin: '0px 5px 0px 0px',
  },
}));

export const ClientDataConfigSelect: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { group = unknownGroup } = useAppSelector((state: AppState) => state?.currentUser);
  const { configurators: configs = [] } = group;
  const selectedViewerId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId,
  );
  const { clientId, clientDataType } = useAppSelector((state: AppState) => state?.clientData);

  const [filteredConfigs, setFilteredConfigs] = React.useState(configs);
  const [clientDataId, setClientDataId] = React.useState(
    getClientDataIdFromUrl() ||
      mapClientAndDataTypeToClientDataId(
        selectedViewerId,
        getVendorFromClientId(selectedViewerId) === ClientDataType.Reference
          ? ClientDataType.Reference
          : ClientDataType.Supplier,
      ),
  );

  const setSelectedClientDataId = (selectedClientDataId: string) => {
    setClientDataId(selectedClientDataId);
    setClientDataIdInUrl(selectedClientDataId);
    dispatch(setSelectedTable(''));
  };

  const setClientAndDataType = (id: string, dataType: ClientDataType) => {
    dispatch(setClientId(id));
    dispatch(setClientDataType(dataType));
    dispatch(setSelectedClientId(id));
    dispatch(setSelectedTabId(id));
  };

  const availableClientDataOptions: { id: string; name: string; clientDataType: ClientDataType }[] = filteredConfigs
    .map((config) => {
      const configClientId = mapConfiguratorToClientId(config);
      if (config.vendor === ClientDataType.Reference) {
        return [
          {
            id: mapClientAndDataTypeToClientDataId(configClientId, ClientDataType.Reference),
            name: config.name || 'Reference Data',
            clientDataType: ClientDataType.Reference,
          },
        ];
      }
      const hasSupplierData = config?.vendorData?.vendor?.supplierKey === getVendorFromClientId(configClientId);
      return [ClientDataType.Vendor, hasSupplierData ? [ClientDataType.Supplier] : []].flat().map((dataType) => {
        const configClientDataId = mapClientAndDataTypeToClientDataId(configClientId, dataType);
        const capitalizedDataType = dataType.charAt(0).toUpperCase() + dataType.slice(1);
        return {
          id: configClientDataId,
          name: `${config.name} - ${capitalizedDataType} Data`,
          clientDataType: dataType,
        };
      });
    })
    .flat();

  // Check if clientDataId is in the availableClientDataOptions and i
  if (!availableClientDataOptions.some((option) => option.id === clientDataId)) {
    if (availableClientDataOptions.length === 0) {
      dispatch(setToastMessage({ type: ToastMessageType.Error, message: t(I18nKeys.ToastNoClientDataAvailable) }));
    } else {
      setSelectedClientDataId(availableClientDataOptions[0].id);
    }
  } else if (!getClientDataIdFromUrl()) {
    setClientDataIdInUrl(clientDataId);
  }

  useEffect(() => {
    const newConfigs = configs.filter((c) => getEnabledOnProperty(c.vendorData, 'clientId', false));
    if (JSON.stringify(filteredConfigs) !== JSON.stringify(newConfigs)) setFilteredConfigs(newConfigs);
  }, [filteredConfigs, configs]);

  useEffect(() => {
    const { clientId: newClientId, clientDataType: newClientDataType = ClientDataType.Supplier } =
      getClientAndDataTypeFromClientDataId(clientDataId);
    if (newClientId !== clientId || newClientDataType !== clientDataType) {
      setClientAndDataType(newClientId, newClientDataType);
    }
  }, [clientDataId]);

  return (
    <Select
      variant="standard"
      disableUnderline
      value={clientDataId}
      onChange={(e: SelectChangeEvent<string>) => setSelectedClientDataId(e.target.value)}
      className={classes.select}
      SelectDisplayProps={{
        className: classes.selectDisplay,
      }}
    >
      {availableClientDataOptions.map((option) => (
        <MenuItem value={option.id} key={option.id} id={option.id}>
          <Tooltip title={option.id} placement="right">
            <div>{option.name}</div>
          </Tooltip>
        </MenuItem>
      ))}
    </Select>
  );
};
