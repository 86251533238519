import { PricingSheetDimensions } from '../types/Pricing';

export enum PricingTab {
  Base = 'base',
  Component = 'component',
  SizeBased = 'size-based',
  Surcharge = 'surcharge',
}

export enum PricingSheetDimension {
  Width = 'width',
  Length = 'length',
  Height = 'height',
  Region = 'region',
  PanelWidth = 'panelWidth',
  None = 'none',
}

export const currencySymbols = ['$', '€', '£', '¥', '₣'];

export enum DefaultPricingColumnFieldNames {
  RowSpanLabel = 'rowSpanLabel',
}

export const pricingBaseGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Width,
  y: PricingSheetDimension.Length,
};

export const pricingBaseListView: PricingSheetDimensions = {
  x: PricingSheetDimension.Region,
  y: PricingSheetDimension.Length,
};

export const pricingSizeBasedLengthGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Length,
  y: PricingSheetDimension.Height,
};

export const pricingSizeBasedWidthGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Width,
  y: PricingSheetDimension.Height,
};

export const pricingSizeBasedPanelWidthGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Length,
  y: PricingSheetDimension.PanelWidth,
};

export const pricingSizeBasedWidthOnlyGridView: PricingSheetDimensions = {
  x: PricingSheetDimension.Width,
  y: PricingSheetDimension.None,
};
