import { I18nKeys } from './I18nKeys';
import { ClientDataBranch } from './ClientDataBranch';

export const CHANGE_SUMMARY_TABLE = 'changeSummary';
export const CELL_METADATA_TABLE = 'cellMetadata';
export const SITE_DETAIL_TABLE = 'siteDetail';
export const PRICING_SURCHARGE_TABLE = 'pricingSurcharge';
export const REGION_TABLE = 'region';
export const SHEDS_STYLE_TABLE = 'style';
export const OPTION_CONDITION_TABLE = 'optionCondition';

export const TABLE_FILTERS = 'clientDataTableFilters';
export const DEFAULT_TEXT_FILTER_TYPE = 'contains';

export const EXPRESSION_DEBUG_DELIMITER = '##';

export enum KeyboardKeys {
  Left = 'ArrowLeft',
  Up = 'ArrowUp',
  Right = 'ArrowRight',
  Down = 'ArrowDown',
  Delete = 'Delete',
  Backspace = 'Backspace',
  Minus = '-',
  Enter = 'Enter',
  Tab = 'Tab',
  Home = 'Home',
  End = 'End',
  Space = ' ',
  Escape = 'Escape',
}

export const COMMIT_SAVE_PREFIX = 'Auto-save:';
export const COMMIT_PRICING_BASE_SAVE_PREFIX = 'Auto-save pricing base:';
export const COMMIT_SAVE_PREFIX_INTEGRATION = 'Auto-save integration:';
export const ROW_DELIMITER = '\n';
export const COLUMN_DELIMITER = '\t';

export enum CellMetadataProperty {
  Formula = 'formula',
  Note = 'note',
}

export enum SearchType {
  CurrentSelection = 'CURRENT_SELECTION',
  CurrentTable = 'CURRENT_TABLE',
  AllTables = 'ALL_TABLES',
  AllVendorsCurrentTable = 'ALL_VENDORS_CURRENT_TABLE',
  AllVendorsAllTables = 'ALL_VENDORS_ALL_TABLES',
}

export enum SearchOptions {
  SearchFormulas = 'searchFormulas',
  ShowReplace = 'showReplace',
  FindAll = 'findAll',
  MatchCase = 'matchCase',
}

export enum TopBarSections {
  Settings = 'settings',
  Utility = 'utility',
}

export enum SettingsOptions {
  ShowFormulas = 'showFormulas',
}

export enum UtilityOptions {
  KeyboardShortcuts = 'keyboardShortcuts',
  VerifyQuotes = 'verifyQuotes',
  GenerateOptionIcons = 'generateOptionIcons',
}

export const topBarOptions = [
  {
    key: SettingsOptions.ShowFormulas,
    value: SettingsOptions.ShowFormulas,
    i18nKey: I18nKeys.SettingsOptionsShowFormulas,
    keyboardShortcut: '⌃ `',
    section: TopBarSections.Settings,
  },
  {
    key: UtilityOptions.KeyboardShortcuts,
    value: UtilityOptions.KeyboardShortcuts,
    i18nKey: I18nKeys.UtilityOptionsKeyboardShortcuts,
    keyboardShortcut: '⌘ /',
    section: TopBarSections.Utility,
  },
  {
    key: UtilityOptions.VerifyQuotes,
    value: UtilityOptions.VerifyQuotes,
    i18nKey: I18nKeys.UtilityOptionsVerifyQuotes,
    keyboardShortcut: '',
    section: TopBarSections.Utility,
  },
  {
    key: UtilityOptions.GenerateOptionIcons,
    value: UtilityOptions.GenerateOptionIcons,
    i18nKey: I18nKeys.UtilityOptionsGenerateOptionIcons,
    keyboardShortcut: '',
    section: TopBarSections.Utility,
  },
];

export const defaultSearchOptions = {
  [SearchOptions.SearchFormulas]: false,
  [SearchOptions.ShowReplace]: false,
  [SearchOptions.FindAll]: false,
  [SearchOptions.MatchCase]: false,
};

export const addRowsQuantities = [5, 10, 25, 100];
export const defaultAddRowsQuantity = addRowsQuantities[0];

export enum ColumnDataType {
  ArrayOfNumbers = 'arrayOfNumbers',
  ArrayOfStrings = 'arrayOfStrings',
  Boolean = 'boolean',
  Enum = 'enum',
  Expression = 'expression',
  File = 'file',
  Html = 'html',
  Image = 'image',
  Json = 'json',
  KMLMap = 'kmlMap',
  Lookup = 'lookup',
  OptionIcon = 'optionIcon',
  Price = 'price',
  Range = 'range',
  StyleIcon = 'styleIcon',
  Text = 'text',
  TextLocalizable = 'textLocalizable',
  Url = 'url',
}

export enum ClientDataUserPreferencesProps {
  UserTableMetadata = 'userTableMetadata',
}

export enum ColumnEventType {
  Moved = 'columnMoved',
  Resized = 'columnResized',
  Pinned = 'columnPinned',
  Visible = 'columnVisible',
  EverythingChanged = 'columnEverythingChanged',
}

export enum ColumnEventSource {
  Api = 'api',
  AutosizeColumns = 'autosizeColumns',
  ColumnMenu = 'columnMenu',
  ContextMenu = 'contextMenu',
  ToolPanelUi = 'toolPanelUi',
  UiColumnResized = 'uiColumnResized',
  UiColumnMoved = 'uiColumnMoved',
}

export enum DoltConstraintViolationType {
  ForeignKey = 'foreign key',
  UniqueIndex = 'unique index',
  CheckConstraint = 'check constraint',
}

export enum DoltDiffType {
  Added = 'added',
  Removed = 'removed',
  Modified = 'modified',
}

export enum MergeStatus {
  Succeed = 'succeed',
  Failed = 'failed',
  Conflicts = 'conflicts',
}

export enum ConflictSolution {
  Base = 'base',
  Our = 'our',
  Their = 'their',
}

export const BRANCH_LABELS = {
  [ClientDataBranch.Main]: I18nKeys.ClientDataBranchPublished,
  [ClientDataBranch.Unpublished]: I18nKeys.ClientDataBranchUnpublished,
  [ClientDataBranch.Hotfix]: I18nKeys.ClientDataBranchHotfix,
  [ClientDataBranch.Pricing]: I18nKeys.ClientDataBranchPricing,
  [ClientDataBranch.SiteDetail]: I18nKeys.ClientDataBranchMenuSiteDetail,
  [ClientDataBranch.ClientUpdate]: I18nKeys.ClientDataBranchClientUpdate,
  [ClientDataBranch.PricingSizeBased]: I18nKeys.ClientDataBranchSizeBased,
  [ClientDataBranch.PricingSurcharge]: I18nKeys.ClientDataBranchPricingSurcharge,
};

export interface ClientPublishedVersions {
  latestVersion: number | undefined;
  vendors?: {
    key: string;
    fileExists: boolean;
    published: {
      clientVersion: number;
      structureVersion: number;
      systemVersion: number;
    } | null;
  }[];
  suppliers?: {
    key: string;
    fileExists: boolean;
    latestVersion: number | undefined;
    published: {
      clientVersion: number;
      structureVersion: number;
      systemVersion: number;
    } | null;
    versions: {
      version: number;
      hash: string;
      author: string;
      date: string;
      message: string;
    }[];
  }[];
  system?: {
    latestVersion: number | undefined;
    publishedVersion: number | undefined;
    versions: {
      version: number;
      hash: string;
      author: string;
      date: string;
      message: string;
    }[];
  };
}

export interface ClientVersion {
  version: number;
  hash: string;
  author: string;
  date: string;
  message: string;
}

export enum IFSIconImagesSize {
  Width = 166,
  Height = 166,
}

export enum IFCIconImagesSize {
  Width = 220,
  Height = 122,
}

export enum OptionIconImagesSize {
  Width = 64,
  Height = 64,
}

export enum ClientDataCacheTagType {
  Branches = 'Branches',
  BranchTableDiff = 'BranchTableDiff',
  TableData = 'TableData',
  TablesData = 'TablesData',
  PublishedVersions = 'PublishedVersions',
  ChangesSummary = 'ChangesSummary',
  CellMetadata = 'CellMetadata',
  VendorData = 'VendorData',
  ComponentCategoryItems = 'ComponentCategoryItems',
  ComponentCategories = 'ComponentCategories',
  Regions = 'Regions',
  SizeBasedCategories = 'SizeBasedCategories',
}

export enum PricingDataCacheTagType {
  PricingSheets = 'PricingSheets',
}

export interface ClientDataCellHistoryChange {
  author: string;
  email: string;
  date: string;
  diffType: DoltDiffType;
  from?: string | null | undefined;
  to?: string | null | undefined;
  fromFormula?: string | null | undefined;
  toFormula?: string | null | undefined;
  version: number | ClientDataBranch;
  message?: string | undefined;
}

export enum ClientDataEditor {
  Standard = 'standard',
  Large = 'large',
}
