import React from 'react';
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { IntegrationDeactivateOptions } from '../types/DialogState';
import { putIntegration } from '../ducks/integrationSlice';

const useStyles = makeStyles(() => ({
  dialogSubtitle: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '22px',
    overflow: 'hidden',
    color: '#000000DE',
    marginBottom: '16px',
  },
  deactivateButton: {
    color: '#F44336',
  },
}));

export const IntegrationsDeactivateDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { options = {} } = useAppSelector((state) => state.dialog || {});
  const { integration, formData } = options as IntegrationDeactivateOptions;

  const openPreviousDialog = () => {
    dispatch(openDialog({ dialog: Dialogs.Integations, options: { integration, formData } }));
  };

  return (
    <Dialog dialogKey={Dialogs.IntegationsDeactivate} onClosed={openPreviousDialog}>
      <DialogContent>
        <Typography
          className={classes.dialogSubtitle}
          component="span"
          dangerouslySetInnerHTML={{
            __html: t(I18nKeys.IntegrationsDeactivateMessage, { context: integration ? integration.type : undefined }),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
            openPreviousDialog();
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button
          onClick={(): void => {
            dispatch(putIntegration({ integration, enabled: false }));
            dispatch(closeDialogFunc());
          }}
          className={classes.deactivateButton}
        >
          {t(I18nKeys.DialogDeactivateButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
