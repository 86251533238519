import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect } from 'react';
import { reset, submit } from 'redux-form';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { Forms } from '../constants/Forms';
import { closeDialog, closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientDataHotfixBranchForm, FormData } from './ClientDataHotfixBranchForm';
import { LoadingButton } from './LoadingButton';
import { useAddBranchMutation } from '../services/clientDataApi';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataHotfixBranchFields } from '../constants/FormFields';
import { setClientDataBranch } from '../ducks/clientDataSlice';
import { KeyboardKeys } from '../constants/ClientData';
import { unknownGroup } from '../constants/Group';

export const ClientDataHotfixBranchDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientDataType, clientId } = useAppSelector((state) => state?.clientData);
  const { key: dialogKey } = useAppSelector((state) => state?.dialog);
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);

  const [addBranch, { isLoading }] = useAddBranchMutation({
    fixedCacheKey: 'revert',
  });

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === KeyboardKeys.Enter && dialogKey === Dialogs.ClientDataHotfixBranch) {
        dispatch(submit(Forms.ClientDataHotfixBranch));
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <Dialog dialogKey={Dialogs.ClientDataHotfixBranch} maxWidth="sm">
      <DialogTitle>{t(I18nKeys.ClientDataHotfixDialogTitle)}</DialogTitle>
      <DialogContent>
        <ClientDataHotfixBranchForm
          isSubmitting={isLoading}
          onSubmit={(formData: FormData) => {
            addBranch({
              branch: ClientDataBranch.Hotfix,
              dataType: clientDataType,
              clientId,
              groupId,
              description: formData[ClientDataHotfixBranchFields.Description],
            })
              .unwrap()
              .then(() => {
                dispatch(setClientDataBranch(ClientDataBranch.Hotfix));
                dispatch(closeDialog());
              });
          }}
        />
      </DialogContent>
      <DialogActions style={{ padding: '0px 8px 8px 8px' }}>
        <Button
          onClick={(): void => {
            dispatch(reset(Forms.ClientDataHotfixBranch));
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <LoadingButton
          onClick={(): void => {
            dispatch(submit(Forms.ClientDataHotfixBranch));
          }}
          loading={isLoading}
          color="primary"
        >
          {t(I18nKeys.DialogCreateButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
