import React from 'react';
import { Grid2 as Grid, Stack, Typography, Card, CardContent, CardActionArea, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { navigate } from 'hookrouter';
import { Integration, IntegrationName, IntegrationStatus } from '@idearoom/types';
import { useTranslation } from 'react-i18next';
import menuIntegrationsIcon from '../images/menuIntegrationsIcon.svg';
import adyenIcon from '../images/adyen.png';
import authorizeDotNetIcon from '../images/authorizeDotNet.png';
import hubSpotIcon from '../images/hubSpot.png';
import zipTaxIcon from '../images/zipTax.png';
import greenCheckConnected from '../images/greenCheckConnected.svg';
import warningIcon from '../images/warning.svg';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { openConfirmationDialog } from '../ducks/confirmation';
import { setFocusedMenuItem } from '../ducks/menu';
import { MenuItems } from '../constants/MenuItems';
import { AppRoutes } from '../constants/AppRoutes';
import { I18nKeys } from '../constants/I18nKeys';
import { putIntegration } from '../ducks/integrationSlice';

interface StyleProps {
  cardHeight?: number;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  integrationCardIconSquare: {
    height: '75px',
    width: '75px',
    borderRadius: '4px',
    display: 'flex',
  },
  integrationCardDefaultIconSquareColor: {
    background: '#0000001A',
  },
  integrationCardIconSquareColor: {
    background: '#FFF',
  },
  integrationCardDefaultIcon: { marginLeft: '16px', marginTop: '12px', width: '48px', height: '48px' },
  integrationCardIcon: { width: '100%', height: '100%' },
  integrationCardTypeText: {
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  integrationCardNameText: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '22px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  integrationCardDisclaimerText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
  integrationCardConnectedText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  oneLineText: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  deactivatedColorText: {
    color: '#F44336',
  },
  connectColorText: {
    color: '#000000DE',
  },
  cardHeight: {
    height: (props) => (props.cardHeight ? `${props.cardHeight}px` : '100%'),
  },
}));

const IntegrationsIcon: React.FC<{ name: IntegrationName }> = ({ name }) => {
  const classes = useStyles({});
  let customIcon;
  switch (name) {
    case IntegrationName.Adyen:
      customIcon = adyenIcon;
      break;
    case IntegrationName.AuthorizeDotNet:
      customIcon = authorizeDotNetIcon;
      break;
    case IntegrationName.ZipTax:
      customIcon = zipTaxIcon;
      break;
    case IntegrationName.HubSpot:
      customIcon = hubSpotIcon;
      break;
    default:
      break;
  }

  return (
    <div
      className={`${classes.integrationCardIconSquare} ${
        customIcon ? classes.integrationCardIconSquareColor : classes.integrationCardDefaultIconSquareColor
      }`}
    >
      <img
        alt={`Integration ${name} Icon`}
        src={customIcon || menuIntegrationsIcon}
        className={customIcon ? classes.integrationCardIcon : classes.integrationCardDefaultIcon}
      />
    </div>
  );
};

export const IntegrationsCard: React.FC<{ integration: Integration; cardHeight?: number | undefined }> = ({
  integration,
  cardHeight,
}) => {
  const classes = useStyles({ cardHeight });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const connected = integration.status === IntegrationStatus.Connected;
  const deactivated = integration.status === IntegrationStatus.Deactivated;

  const onClickCard = () => {
    if (deactivated) {
      dispatch(
        openConfirmationDialog(
          undefined,
          t(I18nKeys.IntegrationsReactivateDialogMessage, {
            integration: t(I18nKeys.IntegrationsName, { context: integration.name }),
          }),
          undefined,
          [{ label: t(I18nKeys.DialogActivateButton), actions: [putIntegration({ integration, enabled: true })] }],
        ),
      );
      dispatch(openDialog({ dialog: Dialogs.Confirmation }));
    } else if (integration.name === IntegrationName.Adyen && integration.status !== IntegrationStatus.Connected) {
      dispatch(setFocusedMenuItem(MenuItems.Payments));
      navigate(AppRoutes.Payments);
    } else {
      dispatch(
        openDialog({
          dialog: Dialogs.Integations,
          options: { integration },
        }),
      );
    }
  };

  return (
    <Grid size={{ sm: 6, lg: 4, xl: 3 }} flexGrow={1} alignItems="stretch">
      <Card className={classes.cardHeight} elevation={3}>
        <CardActionArea component="a" className={classes.cardHeight} onClick={onClickCard}>
          <CardContent>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <IntegrationsIcon name={integration.name} />
                <Stack justifyContent="center">
                  <Typography className={`${classes.integrationCardTypeText} ${classes.oneLineText}`}>
                    {t(I18nKeys.IntegrationsType, { context: integration.type })}
                  </Typography>
                  <Typography className={`${classes.integrationCardNameText} ${classes.oneLineText}`}>
                    {t(I18nKeys.IntegrationsName, { context: integration.name })}
                  </Typography>
                </Stack>
              </Stack>
              {connected || deactivated ? (
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <img
                      alt="Connected"
                      width="24px"
                      height="24px"
                      src={deactivated ? warningIcon : greenCheckConnected}
                    />
                    <Typography
                      className={`${classes.integrationCardConnectedText} ${classes.oneLineText} ${
                        deactivated ? classes.deactivatedColorText : classes.connectColorText
                      }`}
                    >
                      {deactivated ? t(I18nKeys.IntegrationsDeactivated) : t(I18nKeys.IntegrationsConnected)}
                    </Typography>
                  </Stack>
                  <Button variant="outlined">
                    {deactivated ? t(I18nKeys.DialogActivateButton) : t(I18nKeys.DialogSettingsButton)}
                  </Button>
                </Stack>
              ) : (
                <Typography className={classes.integrationCardDisclaimerText}>
                  {t(I18nKeys.IntegrationsCardDisclaimer, { context: integration.name })}
                </Typography>
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
