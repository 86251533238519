import moment from 'moment';
import { DateRange } from '../types/DateRange';

export const getDateAsNumbers = (date: string): string => moment(new Date(date)).format('M/D/YYYY');

export const getDateAsText = (date: string): string => moment(new Date(date)).format('MMMM D, YYYY');

export const getDateWithTime = (date: string): string => moment(new Date(date)).format('YYYY-M-D h:mm:ss A');

export const getDateTimeString = (date: string): string => moment(new Date(date)).format('M/D/YYYY h:mm A');

/**
 * Get the date with the month as an abbreviation, with the day and time. Year shown if not current year.
 */
export const getDateWithMonthAbbreviation = (date: Date | string, showYear = false): string => {
  const dateMoment = moment(new Date(date));
  if (dateMoment.format() === 'Invalid date') return dateMoment.format();
  const year = dateMoment.format('YYYY');
  const month = dateMoment.format('MMM');
  const day = dateMoment.format('D');
  const time = dateMoment.format('h:mm A');
  const currentYear = moment().format('YYYY');
  return `${month} ${day}, ${showYear || year !== currentYear ? `${year}, ` : ''}${time}`;
};

export const getLastDaysDateRange = (numberOfDays: number): DateRange => ({
  startDate: moment().subtract(numberOfDays, 'days').startOf('day'),
  endDate: moment().endOf('day'),
});

export const getCurrentMonthDateRange = (): DateRange => ({
  startDate: moment().startOf('day').startOf('month'),
  endDate: moment().endOf('day'),
});

export const getPriorMonthDateRange = (): DateRange => ({
  startDate: moment().subtract(1, 'month').startOf('day').startOf('month'),
  endDate: moment().subtract(1, 'month').endOf('month').endOf('day'),
});
