import { Dispatch } from 'redux';
import { TFunction } from 'i18next';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { ClientDataBranchMetadata, PublishingResult } from '../types/ClientData';
import { PublishBarButton } from '../types/Viewer';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import {
  setPricingBaseDataBranch,
  setPricingComponentDataBranch,
  setPricingSizeBasedDataBranch,
} from '../ducks/pricingSlice';
import { ClientDataType } from '../constants/ClientDataType';
import { config } from '../config/config';
import { sitesText } from '../constants/VendorData';
import { setClientDataBranch } from '../ducks/clientDataSlice';
import { MergeStatus } from '../constants/ClientData';
import { setSiteDetailPublishMergeResult } from '../ducks/vendorDataSlice';
import { getConfiguratorPreviewUrl } from './clientDataUtils';
import { mapClientIdToConfiguratorAndVendor } from './clientIdUtils';
import { getConfiguratorUrlWithLocale } from './vendorUtils';

export const getSharedPricingButtons = ({
  dispatch,
  t,
  clientId,
  clientDataType,
  groupId,
  branch,
  activeBranches,
  deleteBranch,
  isPublishing,
}: {
  dispatch: Dispatch<any>;
  t: TFunction;
  clientId: string;
  clientDataType: ClientDataType;
  groupId: string;
  branch: ClientDataBranch;
  activeBranches: ClientDataBranchMetadata[];
  deleteBranch: (args: {
    dataType: ClientDataType;
    branch: ClientDataBranch;
    clientId: string;
    groupId: string;
  }) => MutationActionCreatorResult<any>;
  isPublishing: boolean;
}): PublishBarButton[] => {
  // Disable buttons if branch does not exist
  const branchExists = !!activeBranches.find((b) => b.branchType === branch);
  let dialog: Dialogs;
  let branchResetFunction: (branch: ClientDataBranch) => void;
  let dialogId: string;

  switch (branch) {
    case ClientDataBranch.Pricing:
      dialog = Dialogs.PricingBasePreview;
      branchResetFunction = setPricingBaseDataBranch;
      dialogId = 'pricing-base';
      break;
    case ClientDataBranch.ClientUpdate:
      dialog = Dialogs.PricingClientUpdatePreview;
      branchResetFunction = setPricingComponentDataBranch;
      dialogId = 'pricing-component';
      break;
    case ClientDataBranch.PricingSizeBased:
      dialog = Dialogs.PricingClientUpdatePreview;
      branchResetFunction = setPricingSizeBasedDataBranch;
      dialogId = 'pricing-size-based';
      break;
    default:
      dialog = Dialogs.PricingSurchargePreview;
      branchResetFunction = setClientDataBranch;
      dialogId = 'pricing-surcharge';
      break;
  }

  return [
    {
      label: t(I18nKeys.RevertButton),
      onClick: (): void => {
        dispatch(
          openConfirmationDialog(
            t(
              branch === ClientDataBranch.ClientUpdate
                ? I18nKeys.PricingComponentRevertConfirmationTitle
                : I18nKeys.PricingBaseRevertConfirmationTitle,
            ),
            t(
              branch === ClientDataBranch.ClientUpdate
                ? I18nKeys.PricingComponentRevertConfirmationMessage
                : I18nKeys.PricingBaseRevertConfirmationMessage,
            ),
            undefined,
            [
              {
                onClick: () => {
                  if (branchExists) {
                    deleteBranch({
                      dataType: clientDataType,
                      branch,
                      clientId,
                      groupId,
                    }).unwrap();
                    dispatch(branchResetFunction(ClientDataBranch.Main));
                  }
                },
                label: t(I18nKeys.RevertButton),
              },
            ],
            false,
            `${dialogId}-revert-`, // confirmation dialog id
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled: !branchExists,
      loading: isPublishing,
      emphasis: 'secondary',
    },
    {
      label: t(I18nKeys.PreviewButton),
      onClick: () => {
        dispatch(
          openDialog({
            dialog,
          }),
        );
      },
      disabled: !branchExists,
      loading: isPublishing,
      emphasis: 'primary',
    },
    {
      label: t(I18nKeys.PublishButton),
      onClick: () => {
        dispatch(openDialog({ dialog: Dialogs.PricingPublish }));
      },
      disabled: !branchExists,
      loading: isPublishing,
      emphasis: 'primary',
    },
  ];
};

export const getSitesButtons = ({
  dispatch,
  t,
  clientId,
  groupId,
  clientDataType,
  clientDataBranch,
  locale,
  productionURL,
  deleteBranch,
  publishClientData,
  isInitializingSelectedTableData,
  isCreatingBranch,
  isPublishing,
}: {
  dispatch: Dispatch<any>;
  t: TFunction;
  clientId: string;
  groupId: string;
  clientDataType: ClientDataType;
  clientDataBranch: ClientDataBranch | undefined;
  locale: string;
  productionURL: string;
  deleteBranch: (args: {
    dataType: ClientDataType;
    branch: ClientDataBranch;
    clientId: string;
    groupId: string;
  }) => MutationActionCreatorResult<any>;
  publishClientData: (args: {
    dataType: ClientDataType;
    branch: ClientDataBranch;
    clientId: string;
    groupId: string;
    message: string;
  }) => MutationActionCreatorResult<any>;
  isInitializingSelectedTableData: boolean;
  isCreatingBranch: boolean;
  isPublishing: boolean;
}): PublishBarButton[] => {
  const disabled =
    clientDataBranch !== ClientDataBranch.SiteDetail ||
    isInitializingSelectedTableData ||
    isCreatingBranch ||
    isPublishing;
  const { configurator, vendor } = mapClientIdToConfiguratorAndVendor(clientId);
  const configuratorUrl = getConfiguratorUrlWithLocale(configurator, vendor, locale, productionURL);
  return [
    {
      label: t(I18nKeys.RevertButton),
      onClick: (): void => {
        dispatch(
          openConfirmationDialog(
            t(sitesText.revertConfirmationTitle),
            t(sitesText.revertConfirmationBody),
            undefined,
            [
              {
                onClick: () => {
                  if (clientDataBranch) {
                    deleteBranch({
                      dataType: clientDataType,
                      branch: clientDataBranch,
                      clientId,
                      groupId,
                    })
                      .unwrap()
                      .then(() => {
                        dispatch(setClientDataBranch(ClientDataBranch.Main));
                      });
                  }
                },
              },
            ],
            false,
            'sites-revert', // confirmation dialog id
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled,
      loading: isPublishing,
      emphasis: 'secondary',
    },
    {
      label: t(I18nKeys.PreviewButton),
      onClick: (): void => {
        const previewUrl = getConfiguratorPreviewUrl(configuratorUrl, clientDataBranch, undefined, undefined);
        window.open(previewUrl, '_blank', 'noopener noreferrer');
      },
      disabled,
      loading: isPublishing,
      emphasis: 'primary',
    },
    {
      label: t(I18nKeys.PublishButton),
      onClick: (): void => {
        dispatch(
          openConfirmationDialog(
            t(sitesText.publishConfirmationTitle),
            t(sitesText.publishConfirmationBody, { env: config.environment.STAGE || '' }),
            undefined,
            [
              {
                onClick: () => {
                  if (clientDataBranch) {
                    publishClientData({
                      dataType: clientDataType,
                      branch: clientDataBranch,
                      clientId,
                      groupId,
                      message: `Publishing site details for ${clientId}`,
                    })
                      .unwrap()
                      .then((result) => {
                        const data = result as PublishingResult;
                        if (data?.mainMerge?.status === MergeStatus.Succeed) {
                          dispatch(setClientDataBranch(ClientDataBranch.Main));
                        }
                        dispatch(setSiteDetailPublishMergeResult({ data, isSuccess: true, error: undefined }));
                      })
                      .catch((error) => {
                        dispatch(setSiteDetailPublishMergeResult({ data: undefined, isSuccess: false, error }));
                        dispatch(openDialog({ dialog: Dialogs.SiteDetailPublishResult }));
                      })
                      .finally(() => {
                        dispatch(openDialog({ dialog: Dialogs.SiteDetailPublishResult }));
                      });
                  }
                },
              },
            ],
            false,
            'sites-publish', // confirmation dialog id
          ),
        );
        dispatch(openDialog({ dialog: Dialogs.Confirmation }));
      },
      disabled,
      loading: isPublishing,
      emphasis: 'primary',
    },
  ];
};
