import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm, Validator } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Grid2 as Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { Forms } from '../constants/Forms';
import { InputField } from './redux-form/InputField';
import { IntegrationsFormFields } from '../constants/FormFields';
import { formatPhoneNumber } from '../utils/phoneNumberUtils';
import { I18nKeys } from '../constants/I18nKeys';

export interface FormData {
  [IntegrationsFormFields.Address1]: string;
  [IntegrationsFormFields.Address2]: string;
  [IntegrationsFormFields.City]: string;
  [IntegrationsFormFields.Country]: string;
  [IntegrationsFormFields.Phone]: string;
  [IntegrationsFormFields.PostalCode]: string;
  [IntegrationsFormFields.State]: string;
}

type FormProps = InjectedFormProps<FormData>;

const useStyles = makeStyles(() => ({
  field: {
    width: '100%',
  },
  fieldBottomText: {
    marginTop: '4px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#00000061',
  },
  fieldBottomStartText: {
    paddingStart: '16px',
  },
  fieldBottomEndText: {
    textAlign: 'end',
  },
}));

const nullOrEmpty: Validator = (value: string) => {
  if (!value || value.length === 0) {
    return 'error';
  }
  return undefined;
};

const IntegrationsAdyenFormComponent: React.FC<FormProps> = ({ handleSubmit, pristine, dirty, reset, submitting }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.IntegrationsAdyenForm}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Field
            autoFocus
            type="string"
            autoComplete="off"
            hideHelperText
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.IntegrationsDialogFormAddress1)}
            validate={nullOrEmpty}
            name={IntegrationsFormFields.Address1}
            variant="filled"
          />
        </Grid>
        <Grid size={12}>
          <Field
            type="string"
            autoComplete="off"
            hideHelperText
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.IntegrationsDialogFormAddress2)}
            name={IntegrationsFormFields.Address2}
            variant="filled"
          />
        </Grid>

        <Grid size={6}>
          <Field
            type="string"
            autoComplete="off"
            hideHelperText
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.IntegrationsDialogFormCity)}
            validate={nullOrEmpty}
            name={IntegrationsFormFields.City}
            variant="filled"
          />
        </Grid>
        <Grid size={3}>
          <Field
            type="string"
            autoComplete="off"
            hideHelperText
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.IntegrationsDialogFormState)}
            validate={nullOrEmpty}
            name={IntegrationsFormFields.State}
            variant="filled"
          />
        </Grid>
        <Grid size={3}>
          <Field
            type="string"
            autoComplete="off"
            hideHelperText
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.IntegrationsDialogFormPostalCode)}
            validate={nullOrEmpty}
            name={IntegrationsFormFields.PostalCode}
            variant="filled"
          />
        </Grid>
        <Grid size={6}>
          <Field
            type="string"
            autoComplete="off"
            hideHelperText
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.IntegrationsDialogFormPhone)}
            format={(value: string | undefined) => formatPhoneNumber(value)}
            validate={nullOrEmpty}
            name={IntegrationsFormFields.Phone}
            variant="filled"
          />
        </Grid>
        <Grid size={6}>
          <Field
            type="string"
            autoComplete="off"
            hideHelperText
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.IntegrationsDialogFormCountry)}
            format={(value?: string) => {
              if (value) {
                const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
                return regionNames.of(value);
              }
              return '';
            }}
            props={{
              disabled: true,
            }}
            validate={nullOrEmpty}
            name={IntegrationsFormFields.Country}
            variant="filled"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export const IntegrationsAdyenForm = reduxForm<FormData>({
  form: Forms.IntegrationsAdyenForm,
})(IntegrationsAdyenFormComponent);
