import React from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { PaymentStatus } from '@idearoom/types';
import { GrayMuted, GreenMuted, OrangeMuted, RedMuted } from '../constants/ChipColors';
import { I18nKeys } from '../constants/I18nKeys';

const getChipColor = (paymentStatus: PaymentStatus | undefined) => {
  switch (paymentStatus) {
    case PaymentStatus.Cancelled:
      return GrayMuted;
    case PaymentStatus.Refunded:
    case PaymentStatus.Refused:
      return RedMuted;
    case PaymentStatus.Authorized:
    case PaymentStatus.Succeeded:
      return GreenMuted;
    default:
      return OrangeMuted;
  }
};

const useStyles = makeStyles<Theme, { paymentStatus: PaymentStatus | undefined }>(() => ({
  root: {
    display: 'flex',
  },
  chip: ({ paymentStatus }) => ({
    padding: '4px 12px 4px 12px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '16px',
    opacity: '0px',
    flexGrow: '0',
    background: getChipColor(paymentStatus),
  }),
  chipText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },
}));

export const StatusChip: React.FC<{ paymentStatus: PaymentStatus | undefined }> = ({
  paymentStatus = PaymentStatus.Pending,
}) => {
  const classes = useStyles({ paymentStatus });
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <div className={classes.chip}>
        <Typography className={classes.chipText}>
          {t(I18nKeys.PaymentGridRowPaymentStatus, { context: paymentStatus.toLowerCase() })}
        </Typography>
      </div>
    </div>
  );
};
