import { Grid2 as Grid, Typography, Theme, alpha, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  setSelectedComponentCategoryKey,
  setSelectedSizeBasedCategoryKey,
  setSelectedSizeBasedPricingSheetId,
} from '../ducks/pricingSlice';
import { CLIENT_UPDATE_CATEGORY_MAPPING } from '../constants/PricingClientUpdate';
import { PricingTab } from '../constants/Pricing';

export const PRICING_CLIENT_UPDATE_NAVIGATION_HEIGHT = 34;

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  breadcrumbs: {
    height: `${PRICING_CLIENT_UPDATE_NAVIGATION_HEIGHT}px`,
    padding: '6.5px 0px 0px',
    '&:not(:last-child)': {
      margin: '0px 0px 10px 0px',
    },
  },
  breadcrumb: {
    cursor: 'pointer',
  },
  faded: {
    color: alpha(theme.palette.text.primary, 0.38),
  },
  chevron: {
    color: alpha(theme.palette.text.primary, 0.54),
    padding: '0px 5px',
  },
}));

export const PricingClientUpdateNavigation: React.FC<{ setSearchValue: (val: string) => void }> = ({
  setSearchValue,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const {
    pricing: {
      component: { selectedCategoryKey: componentCategoryKey },
      sizeBased: { selectedCategoryKey: sizeBasedCategoryKey },
    },
    viewer: { selectedPricingTabId: pricingTab },
  } = useAppSelector((state) => state);

  const categoryKey = pricingTab === PricingTab.Component ? componentCategoryKey : sizeBasedCategoryKey;

  const allCategoriesRef = React.useRef<HTMLDivElement>(null);
  const categoryRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      key: 'allCategories',
      ref: allCategoriesRef,
      label: `All ${pricingTab === PricingTab.Component ? 'Components' : 'Sized Items'}`,
      visible: true,
      onClick: () => {
        dispatch(setSelectedComponentCategoryKey(undefined));
        dispatch(setSelectedSizeBasedCategoryKey(undefined));
      },
    },
    {
      key: 'category',
      ref: categoryRef,
      label: categoryKey ? t(CLIENT_UPDATE_CATEGORY_MAPPING[categoryKey]?.label) : '',
      visible: !!categoryKey,
      onClick: () => {
        dispatch(setSelectedSizeBasedPricingSheetId(undefined));
      },
    },
  ];

  return (
    <Grid className={classes.breadcrumbs} container size={{ xs: 12 }}>
      {breadcrumbs.map(({ key, ref, label, visible }, index, arr) => {
        const visibleBreadcrumbs = arr.filter(({ visible: v }) => v);
        const i = visibleBreadcrumbs.findIndex(({ key: k }) => k === key);
        return (
          <Grid key={key} ref={ref} style={{ overflow: 'hidden' }}>
            <Slide direction="right" in={visible} container={ref.current}>
              <Grid display="inline-flex">
                {index !== 0 && (
                  <Typography className={`${classes.breadcrumb} ${classes.chevron}`} variant="body1" component="div">
                    <ChevronRight />
                  </Typography>
                )}
                <Typography
                  className={`${classes.breadcrumb} ${i < visibleBreadcrumbs.length - 1 ? classes.faded : ''}`}
                  variant="body1"
                  component="div"
                  fontWeight={600}
                  onClick={() => {
                    setSearchValue('');
                    arr.slice(index).forEach(({ onClick: handler }) => handler && handler());
                  }}
                >
                  {label}
                </Typography>
              </Grid>
            </Slide>
          </Grid>
        );
      })}
    </Grid>
  );
};
