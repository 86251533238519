import React from 'react';
import { formValueSelector } from 'redux-form';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../hooks';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { PricingSheetNameForm, FormData } from './PricingSheetNameForm';
import { PricingSheetNameFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { AppState } from '../types/AppState';
import { getPricingSheetDefaultLabel, getPricingSheetTable, updateSheetTitle } from '../utils/pricingSheetUtils';
import { PricingSheet } from '../types/PricingSheet';
import { PricingSheetAttributeType } from '../constants/PricingSheetAttributeType';
import { kebabCaseToTitleCase } from '../utils/stringUtils';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  attributeHeader: {
    marginTop: '16px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  list: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  listItem: {
    paddingTop: '0px',
    marginTop: '0px',
  },
}));

export const PricingSheetNameDialog: React.FC<{ selectedPricingSheet?: PricingSheet }> = ({ selectedPricingSheet }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    viewer: { selectedPricingTabId: pricingTab = '' },
    clientData: { clientId },
  } = useAppSelector((state) => state);
  const initialSheetName =
    selectedPricingSheet?.priceSetLabel || getPricingSheetDefaultLabel(selectedPricingSheet, pricingTab);
  const initialValues = {
    [PricingSheetNameFormFields.SheetTitle]: initialSheetName,
  } as FormData;

  const placeholder = `Pricing Sheet ${selectedPricingSheet?.id || ''}`;
  const currentSheetName = useAppSelector((state: AppState) =>
    formValueSelector(Forms.PricingSheetName)(state, PricingSheetNameFormFields.SheetTitle),
  );

  const saveDisabled = currentSheetName === initialSheetName;

  const onSave = () => {
    if (selectedPricingSheet) {
      updateSheetTitle(
        currentSheetName,
        getPricingSheetTable(clientId, pricingTab, selectedPricingSheet.category),
        selectedPricingSheet,
        dispatch,
      );
      dispatch(closeDialogFunc());
    }
  };

  return (
    <Dialog dialogKey={Dialogs.PricingSheetName}>
      <DialogTitle className={classes.dialogTitle}>{t(I18nKeys.PricingBaseSheetNameDialogTitle)}</DialogTitle>
      <DialogContent>
        <PricingSheetNameForm placeholder={placeholder} initialValues={initialValues} />
        {selectedPricingSheet?.attributes
          .filter(({ hide }) => !hide)
          .map(({ value, type, label }) => {
            const count = `${value}`.split(',').length;

            const displayAsList = !(
              [PricingSheetAttributeType.CustomExpression, PricingSheetAttributeType.SingleSlope] as string[]
            ).includes(type);
            const header = displayAsList
              ? `${count} ${kebabCaseToTitleCase(type).toLowerCase()}${count > 1 ? 's' : ''}`
              : label;

            return (
              <div key={type}>
                <Typography className={classes.attributeHeader}>{header}</Typography>
                {displayAsList && (
                  <ul className={classes.list}>
                    <li className={classes.listItem}>{label || value}</li>
                  </ul>
                )}
              </div>
            );
          })}
      </DialogContent>
      <DialogActions style={{ padding: '0px 8px 8px 8px' }}>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button onClick={onSave} color="primary" disabled={saveDisabled}>
          {t(I18nKeys.DialogSaveButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
