import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { removeDealer } from '../ducks/dealers';
import { openDialog } from '../ducks/dialogSlice';
import { openConfirmationDialog } from '../ducks/confirmation';
import { Dealer } from '../types/Dealer';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch } from '../hooks';

interface Props {
  clientId: string;
  dealer: Dealer;
  disabled: boolean;
}

export const DealerMenu: React.FC<Props> = ({ clientId, dealer, disabled }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event: any): void {
    event.stopPropagation();
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        style={{ padding: '6px' }}
        aria-controls="dealer-menu-button"
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="dealer-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListItemText
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
              dispatch(
                openConfirmationDialog(
                  t(I18nKeys.DealerMenuConfirmRemoveTitle),
                  t(I18nKeys.DealerMenuConfirmRemoveMessage),
                  undefined,
                  [
                    {
                      actions: [removeDealer(clientId, dealer)],
                    },
                  ],
                ),
              );
              dispatch(openDialog({ dialog: Dialogs.Confirmation }));
              handleClose(event);
            }}
            primary={t(I18nKeys.DealerMenuRemoveOption)}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export const getDealerMenuCell = (clientId: string, disabled: boolean) =>
  function ({ row, value }: { row: any; value: any }): JSX.Element {
    return (
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <div>{value}</div>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
          {!row.updating && <DealerMenu dealer={row} clientId={clientId} disabled={disabled} />}
        </div>
      </div>
    );
  };
