import { createListenerMiddleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IntegrationData, IntegrationName } from '@idearoom/types';
import { formValueSelector } from 'redux-form';
import { AppState } from '../types/AppState';
import { isUpdatingIntegration, putIntegration, startPaymentIntegration } from '../ducks/integrationSlice';
import { integrationApi } from '../services/integrationApi';
import { unknownGroup } from '../constants/Group';
import { closeDialog } from '../ducks/dialogSlice';
import { mapConfiguratorToClientId } from '../utils/clientIdUtils';
import { IntegrationsFormFields } from '../constants/FormFields';
import { extractErrorProps } from '../utils/errorUtils';
import { Forms } from '../constants/Forms';
import { COMMIT_SAVE_PREFIX_INTEGRATION } from '../constants/ClientData';

export const integrationListener = createListenerMiddleware<AppState>();

const defaultErrorMessage = 'Something went wrong. Please try again.';

integrationListener.startListening({
  actionCreator: startPaymentIntegration,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const {
      currentUser: { group: { groupId, configurators } = unknownGroup },
      viewer: { selectedClientId = '' },
    } = state;
    const allClientIds = (configurators || [])
      ?.map((config) => mapConfiguratorToClientId(config))
      .filter((clientId) => clientId !== selectedClientId);
    // add selectedClientId as first to be used as default to load vendor data
    const clientIds = [selectedClientId, ...allClientIds];
    const { legalEntityName, shopperStatement } = action.payload || {};

    const createPaymentIntegration = dispatch(
      integrationApi.endpoints.createPaymentIntegration.initiate({
        clientIds,
        groupId,
        legalEntityName,
        shopperStatement,
      }),
    );
    createPaymentIntegration.unsubscribe();
    const { data } = await createPaymentIntegration;
    const { onboardingUrl } = data || {};

    if (onboardingUrl) {
      window.open(onboardingUrl, '_self');
    }
    dispatch(closeDialog());
  },
});

integrationListener.startListening({
  actionCreator: putIntegration,
  effect: async (action, { dispatch, getState }) => {
    dispatch(isUpdatingIntegration(true));
    const state = getState();
    const {
      viewer: { selectedClientId },
      currentUser: { group: { groupId } = unknownGroup },
    } = state;
    const { integration } = action.payload;

    try {
      let message: string | undefined;
      if (integration.data && selectedClientId) {
        let integrationData: IntegrationData | undefined;
        switch (integration.name) {
          case IntegrationName.Adyen: {
            if (action.payload.enabled !== null) {
              integrationData = {
                enabled: action.payload.enabled,
              };
              message = COMMIT_SAVE_PREFIX_INTEGRATION;
            } else {
              const formSelector = formValueSelector(Forms.IntegrationsAdyenForm);
              integrationData = {
                store: {
                  address: {
                    address1: formSelector(state, IntegrationsFormFields.Address1),
                    address2: formSelector(state, IntegrationsFormFields.Address2),
                    city: formSelector(state, IntegrationsFormFields.City),
                    country: formSelector(state, IntegrationsFormFields.Country),
                    postalCode: formSelector(state, IntegrationsFormFields.PostalCode),
                    state: formSelector(state, IntegrationsFormFields.State),
                  },
                  phoneNumber: formSelector(state, IntegrationsFormFields.Phone),
                },
              };
            }
            break;
          }
          default:
            break;
        }

        if (integrationData) {
          await dispatch(
            integrationApi.endpoints.putIntegration.initiate({
              clientId: selectedClientId,
              groupId,
              name: integration.name,
              data: integrationData,
              message,
            }),
          );
        }
      }
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    }
    dispatch(isUpdatingIntegration(false));
  },
});
