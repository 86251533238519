import { SortProperty } from '../types/SortProperty';
import { SortDirection } from '../constants/SortDirection';
import { removeCurrencySymbolsCommasAndSpaces } from './pricingUtils';

export const sortByAlphanumericParts = (a: string, b: string, direction: SortDirection, defaultValue = ''): number => {
  const [partsA, partsB] = [a, b].map((item) => {
    let itemAsString;

    if (Array.isArray(item)) {
      itemAsString = item.join(',');
    }

    if (!itemAsString) {
      itemAsString = `${item}` || defaultValue;
      itemAsString = removeCurrencySymbolsCommasAndSpaces(itemAsString);
    }

    const splitByNumbers = itemAsString.split(/(\d+)/).filter(Boolean);

    return splitByNumbers.map((p) => {
      const parsed = Number(p);
      if (parsed || parsed === 0) return parsed;
      return p;
    });
  });

  const partsToCompare = Math.min(partsA.length, partsB.length);
  for (let i = 0; i < partsToCompare; i += 1) {
    const partA = partsA[i];
    const partB = partsB[i];
    if (partA !== partB) {
      // If both numbers, compare as numbers
      if ([partA, partB].every((p) => typeof p === 'number')) {
        return direction === SortDirection.Asc
          ? (partA as number) - (partB as number)
          : (partB as number) - (partA as number);
      }

      // Otherwise compare as strings
      return direction === SortDirection.Asc
        ? `${partA}`.localeCompare(`${partB}`)
        : `${partB}`.localeCompare(`${partA}`);
    }
  }

  return direction === SortDirection.Asc ? partsA.length - partsB.length : partsB.length - partsA.length;
};

/**
 * Sorts table rows based on the given sort properties
 *
 * @param rows any[]
 * @param sortProperties SortProperty[]
 * @returns void
 */
export const sortRows = (rows: any[], sortProperties: SortProperty[]): void => {
  for (let index = sortProperties.length - 1; index > -1; index -= 1) {
    const { key, direction, defaultValue } = sortProperties[index];
    rows.sort(({ [key]: first }: any, { [key]: second }: any) =>
      sortByAlphanumericParts(first, second, direction, defaultValue),
    );
  }
};

/**
 * Sorts an array of objects based on a given prop. The sorting algorithm takes into account alphanumeric characters.
 * Returns undefined if objects param is not provided
 * Sorts by object if prop param is not provided
 *
 * @param objects any[]
 * @param prop string
 * @returns any[]
 */
export const sortObjectsByAlphanumericStrings = (objects?: any[], prop?: string): any[] | undefined => {
  if (!objects) {
    return objects;
  }
  const sortedObject = objects
    .slice(0)
    .sort((a, b) =>
      `${prop ? a[prop] : a}`.localeCompare(`${prop ? b[prop] : b}`, undefined, { numeric: true, sensitivity: 'base' }),
    );
  return sortedObject;
};
