import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from '../hooks';
import { I18nKeys } from '../constants/I18nKeys';
import { PricingSheetSubheader } from './PricingSheetSubheader';
import { PricingSheet } from '../types/PricingSheet';
import pricingUpchargeIcon from '../images/pricingUpchargeIcon.svg';
import { PricingTab } from '../constants/Pricing';
import { SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import { PricingSheetAttributeType } from '../constants/PricingSheetAttributeType';

const useStyles = makeStyles(() => ({
  subheader: {
    color: '#38761E',
  },
}));

type Props = {
  selectedPricingSheetId?: string;
  pricingSheets: PricingSheet[];
};

export const PricingSidingUpchargeHeader: React.FC<Props> = ({ selectedPricingSheetId, pricingSheets }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { selectedPricingTabId: pricingTab = '' } = useAppSelector((state) => state?.viewer);

  const [siding, setSiding] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    const { category, attributes = [] } =
      pricingSheets?.find((sheet) => selectedPricingSheetId && sheet.id === selectedPricingSheetId) || {};
    const sidingAttribute = attributes.find(({ type }) => type === PricingSheetAttributeType.Siding)?.label;
    if (!sidingAttribute || pricingTab !== PricingTab.SizeBased || category !== SizeBasedCategoryKey.Siding) {
      setSiding(undefined);
      return;
    }
    setSiding(sidingAttribute?.toLowerCase().replace(SizeBasedCategoryKey.Siding, '').trim());
  }, [pricingTab, selectedPricingSheetId, pricingSheets]);

  if (!siding) return null;
  return (
    <PricingSheetSubheader
      subheader={t(I18nKeys.PricingSidingUpchargeHeader, { siding })}
      iconSrc={pricingUpchargeIcon}
      classes={classes}
    />
  );
};
