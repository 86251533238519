/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientDataState } from '../types/ClientDataState';

import { ClientDataBranch } from '../constants/ClientDataBranch';
import { clientDataApi } from '../services/clientDataApi';
import { isOptionAbleToGenerateIcon } from '../utils/clientDataUtils';
import { unknownGroup } from '../constants/Group';
import { AppState } from '../types/AppState';

export const fetchTableDataToGenerateIcons = createAsyncThunk<
  NonNullable<ClientDataState['optionIconsToGenerate']>,
  NonNullable<ClientDataState['optionIconsToGenerate']>,
  { state: AppState }
>('clientData/fetchTableDataToGenerateIcons', async (tablesWithOption, { dispatch, getState }) => {
  const {
    clientData: { clientDataType, clientId, clientDataBranch = ClientDataBranch.Main },
    currentUser: { group: { groupId } = unknownGroup },
  } = getState();

  const loadOptionToGenerateIcon = async (option: NonNullable<ClientDataState['optionIconsToGenerate']>[number]) => {
    const result = { ...option };
    const tableDataFetch = dispatch(
      clientDataApi.endpoints.getClientDataTableData.initiate({
        dataType: clientDataType,
        branch: clientDataBranch,
        clientId,
        groupId,
        table: option.table,
      }),
    );
    tableDataFetch.unsubscribe();
    const { data: tableData = [] } = await tableDataFetch;
    result.rows = tableData.filter((data) => isOptionAbleToGenerateIcon(data, option.column));

    if (result.rows.length > 0) {
      const cellMetadataFetch = dispatch(
        clientDataApi.endpoints.getClientDataCellMetadata.initiate({
          dataType: clientDataType,
          branch: clientDataBranch,
          clientId,
          groupId,
          table: option.table,
        }),
      );
      cellMetadataFetch.unsubscribe();
      const { data: cellMetadata = [] } = await cellMetadataFetch;
      result.cellMetadata = cellMetadata;
    }
    return result;
  };

  const options = (await Promise.all(tablesWithOption.map((option) => loadOptionToGenerateIcon(option)))).filter(
    (option) => option.rows.length > 0,
  );

  return options;
});
