import {
  ColDef,
  GridApi,
  ProcessDataFromClipboardParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
  ColumnApi,
  CellClassParams,
  Column,
  EditableCallbackParams,
  ITooltipParams,
} from 'ag-grid-community';
import { v1 as uuidV1 } from 'uuid';
import { AnyAction, Dispatch } from 'redux';
import { TFunction } from 'i18next';
import { PriceColumn } from '@idearoom/types';
import { PricingSheetPrice, PricingSheetPriceData } from '../types/PricingSheetPrice';
import { BaseTableData } from '../types/DataGrid';
import {
  formatPrice,
  getValidatedNewValue,
  arePriceValuesDifferent,
  getCurrencySymbol,
  isDecimalPrice,
  removeCurrencySymbolsCommasAndSpaces,
} from './pricingUtils';
import {
  getCellRangeInfo,
  getPropertyFromCellMetadata,
  hasCellMetadataProperty,
  processUpdatedValues,
} from './clientDataUtils';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';
import { addDispatchCommandToUndo } from './undoManagerUtils';
import {
  addPricingBaseRow,
  removePricingBaseRows,
  updatePricingSheetRows,
  updatePricingMetadata,
} from '../ducks/pricingSlice';
import { closeDialog, openDialog } from '../ducks/dialogSlice';
import { openNotificationDialog } from '../ducks/notification';
import { Dialogs } from '../constants/Dialogs';
import { LocalStorage } from '../constants/LocalStorage';
import { UpdateClientDataMetadata, UpdateClientDataRow } from '../ducks/clientDataSlice';
import { CellMetadata, ClipboardData, ColumnPinned } from '../types/ClientData';
import { CellMetadataProperty, COLUMN_DELIMITER, ROW_DELIMITER } from '../constants/ClientData';
import { PricingSheet } from '../types/PricingSheet';
import { GridViewType } from '../constants/GridViewType';
import { PriceColumnsByGroupId, PricingSheetRegion, Region } from '../types/Region';
import {
  DefaultPricingColumnFieldNames,
  pricingBaseGridView,
  pricingBaseListView,
  PricingSheetDimension,
  pricingSizeBasedLengthGridView,
  pricingSizeBasedPanelWidthGridView,
  pricingSizeBasedWidthGridView,
  pricingSizeBasedWidthOnlyGridView,
  PricingTab,
} from '../constants/Pricing';
import { PricingSheetDimensions } from '../types/Pricing';
import { SelectAllCellsHeader } from '../components/SelectAllCellsHeader';
import {
  defaultColumn,
  lengthColumnWidth,
  widthLengthColumnWidth,
  decimalPriceColumnWidth,
  priceColumnWidth,
  rowSpanHeaderWidthVertical,
  rowSpanHeaderWidthHorizontal,
  rowSpanHeaderWidthHorizontalExtended,
} from '../constants/PricingBase';
import { getTextWidth } from './htmlUtil';
import { ClientDataTooltip } from '../components/ClientDataTooltip';
import { PricingGridTooltip } from '../components/PricingGridTooltip';
import { SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import { SHEDVIEW, getConfiguratorFromClientId } from './clientIdUtils';
import { getAttributeLabel, PricingSheetAttributeType } from '../constants/PricingSheetAttributeType';
import { openConfirmationDialog } from '../ducks/confirmation';
import { clearSelections } from './selectionUtils';
import { PriceByQuantity } from '../constants/PricingClientUpdate';
import { capitalizeFirstLetter } from './stringUtils';
import { ClientUpdateCategoryKey } from '../types/PricingClientUpdate';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';

export interface PriceRow extends BaseTableData {
  [key: string]: any;
}

/**
 * Gets the columns that should be used to store the price set label for a pricing sheet.
 *
 * @param pricingSheet the pricing sheet to get the price set columns for
 * @returns the columns to store the price set labels for the pricing sheet
 */
export const getPriceSetColumns = (pricingSheet: PricingSheet) => {
  const { category = '', attributes = [] } = pricingSheet || {};

  const sidingDirection = attributes.find(({ type }) => type === PricingSheetAttributeType.SidingDirection)?.value;
  if (
    ([SizeBasedCategoryKey.EndWalls, SizeBasedCategoryKey.SideWalls] as string[]).includes(category) &&
    typeof sidingDirection === 'string'
  ) {
    return sidingDirection.split(',').map((direction) => `${direction.trim()}PriceSetLabel`);
  }
  return [PriceColumn.priceSetLabel];
};

/**
 * Splits a pricing sheet column id into its column group id and standard column id (Ex: 'price' or 'region1')
 *
 * @param column pricing sheet column id
 * @returns column group id and standard column id
 */
export const getColumnAndColumnGroupId = (column: string): { columnGroupId: string; columnId: string } => {
  const [columnGroupId, ...columnId] = column.split('-');
  return { columnGroupId, columnId: columnId.join('-') };
};

/**
 * Splits a pricing sheet column id and returns just its standard column id (Ex: 'price' or 'region1')
 *
 * @param column pricing sheet column id
 * @returns standard column id
 */
export const getColumnId = (column: string): { columnId: string } => {
  const [, ...columnId] = column.split('-');
  return { columnId: columnId.length > 0 ? columnId.join('-') : column };
};

/**
 * Returns a list of unique values for a given dimension in a list of pricing sheet prices
 *
 * @param dimension dimension to get the unique values for (Ex: 'width' or 'height')
 * @param prices pricing sheet prices
 * @returns list of unique values for the given dimension
 */
export const getAllUniqueDimensionValues = (dimension: PricingSheetDimension, prices: PricingSheetPrice[]) => {
  if (dimension === PricingSheetDimension.None) return [PricingSheetDimension.None];
  const allValues = prices.reduce((allValuesSeen: string[], price: PricingSheetPrice) => {
    const value = (price as Record<string, any>)[dimension];
    if (value && !allValuesSeen.includes(`${value}`)) {
      allValuesSeen.push(`${value}`);
    }
    return allValuesSeen;
  }, []);
  return allValues.sort((a, b) => Number.parseInt(a, 10) - Number.parseInt(b, 10));
};

export interface PricesPerRegionDiff {
  diff: number;
  missing: number;
}

/**
 * Returns whether a list of regions is using the default price column for all column groups
 *
 * @param regions pricing sheet regions
 * @param defaultPriceColumn default price column object
 * @returns whether the regions are using the default price column
 */
export const hasDefaultRegionFunc = (regions: PricingSheetRegion[], defaultPriceColumn: PriceColumnsByGroupId) =>
  regions.some(({ priceColumn }) =>
    Object.entries(priceColumn).every(([columnGroupId, column]) => column === defaultPriceColumn[columnGroupId]),
  );

export const getAvailablePricesPerRegionDiff = (
  configurator: string | undefined,
  availablePricePerRegion = new Map<string, PricesPerRegionDiff>(),
  regions: PricingSheetRegion[],
  defaultPriceColumn: PriceColumnsByGroupId,
  prices: PricingSheetPrice[],
): Map<string, { diff: number; missing: number }> => {
  const hasDefaultRegion = hasDefaultRegionFunc(regions, defaultPriceColumn);
  if (!hasDefaultRegion) return new Map();

  regions.forEach(({ rowId, priceColumn }) => {
    let diff = 0;
    let missing = 0;
    Object.entries(priceColumn).forEach(([columnGroupId, column]) => {
      const defaultColumnGroupColumn = defaultPriceColumn[columnGroupId];
      if (column !== defaultColumnGroupColumn) {
        prices.forEach((p) => {
          if (arePriceValuesDifferent(p[column], p[defaultColumnGroupColumn])) diff += 1;
          if (configurator === SHEDVIEW && (p[column] || '') === '' && (p[defaultColumnGroupColumn] || '') !== '')
            missing += 1;
        });
      }
    });
    availablePricePerRegion.set(rowId, { diff, missing });
  });
  return availablePricePerRegion;
};

export const getSubtitleText = (
  availablePricePerRegion: Map<string, PricesPerRegionDiff>,
  sumAvailablePrices: number | undefined,
  region: PricingSheetRegion,
  defaultPriceColumn: PriceColumnsByGroupId,
  isHiddenRegion: boolean,
) => {
  if (isHiddenRegion) {
    return i18n.t(I18nKeys.PricingBaseAccordionHiddenRegion);
  }

  if (
    Object.entries(region.priceColumn).every(([columnGroupId, column]) => column === defaultPriceColumn[columnGroupId])
  ) {
    return i18n.t(I18nKeys.PricingBaseAccordionDefaultLabel);
  }
  const { diff = 0, missing = 0 } = availablePricePerRegion.get(region.rowId) || {};

  if (diff === 0) {
    return i18n.t(I18nKeys.PricingBaseAccordionNonePriceChanged, { total: sumAvailablePrices });
  }

  // TODO(Kevin): after deployed on production we should remove the i18n entry that does not use the missing interpolation
  let missingPriceText;
  if (missing > 0) {
    missingPriceText = i18n.t(I18nKeys.PricingBaseAccordionMissingPrice, {
      count: missing,
    });
  }

  if (diff === sumAvailablePrices) {
    return missingPriceText
      ? i18n.t(I18nKeys.PricingBaseAccordionAllPricesChangedMissing, {
          total: diff,
          missing: missingPriceText,
        })
      : i18n.t(I18nKeys.PricingBaseAccordionAllPricesChanged, {
          total: diff,
        });
  }

  return missingPriceText
    ? i18n.t(I18nKeys.PricingBaseAccordionCountPricesChangedMissing, {
        count: diff,
        missing: missingPriceText,
        total: sumAvailablePrices || 0,
      })
    : i18n.t(I18nKeys.PricingBaseAccordionCountPricesChanged, {
        count: diff,
        total: sumAvailablePrices || 0,
      });
};

const getDimensionHeaderLabel = (dimension: PricingSheetDimension, t: Function) => {
  let labelKey = '';
  switch (dimension) {
    case PricingSheetDimension.Length:
      labelKey = I18nKeys.PricingSheetLength;
      break;
    case PricingSheetDimension.Width:
      labelKey = I18nKeys.PricingSheetWidth;
      break;
    case PricingSheetDimension.Height:
      labelKey = I18nKeys.PricingSheetHeight;
      break;
    case PricingSheetDimension.PanelWidth:
      labelKey = I18nKeys.PricingSheetPanelWidth;
      break;
    default:
      break;
  }
  return t(labelKey);
};

const getRowSpanHeaderWidth = (dimension: PricingSheetDimension, rowsToSpan: number): number => {
  if (rowsToSpan > 3) return rowSpanHeaderWidthVertical;

  switch (dimension) {
    case PricingSheetDimension.PanelWidth:
    case PricingSheetDimension.Height:
      return rowSpanHeaderWidthHorizontalExtended;
    default:
      return rowSpanHeaderWidthHorizontal;
  }
};

/**
 * Gets whether the region has any prices that are set
 *
 * @param prices
 * @param region
 * @returns
 */
export const regionHasPrices = (prices: PricingSheetPrice[] | undefined, region: PricingSheetRegion) =>
  prices &&
  prices.some((price) =>
    Object.values(region.priceColumn).some(
      (column) => price[column] !== undefined && price[column] !== null && price[column] !== '',
    ),
  );

/**
 * Creates the row of prices from the pricing sheet. Sorts the rows based on available lengths.
 * @param prices
 * @param unit
 * @param gridViewType
 * @param pricingSheetDimensions
 * @param priceColumn
 * @param defaultPriceColumn
 * @param regions
 * @returns
 */
export const getRowData = (
  prices: PricingSheetPrice[],
  unit: string,
  gridViewType: GridViewType,
  pricingSheetDimensions: PricingSheetDimensions,
  priceColumn: PriceColumnsByGroupId | undefined,
  defaultPriceColumn: PriceColumnsByGroupId | undefined,
  regions: PricingSheetRegion[] = [
    {
      priceColumn: defaultPriceColumn || {},
      supplierKey: '',
      enabled: true,
      exclusionZone: false,
      label: '',
      rowId: '',
      regionKey: '',
    },
  ],
) => {
  const { x, y } = pricingSheetDimensions;
  const rowData: PriceRow[] = [];

  // return row data based on grid view type
  if (gridViewType === GridViewType.Grid) {
    const xAxis = x as PricingSheetDimension.Length | PricingSheetDimension.Width;
    const yAxisValues = getAllUniqueDimensionValues(y, prices);
    yAxisValues.forEach((value, i) => {
      let rowSpanLabel = '';
      if (i === 0) {
        rowSpanLabel = getDimensionHeaderLabel(y, i18n.t);
      }
      const pricesForThisDimension = prices.filter(
        (price) => y === PricingSheetDimension.None || `${price[y]}` === value,
      );

      const row: PriceRow = {
        rowId: `${i}`,
        priceY: value,
        ...(y === PricingSheetDimension.None ? {} : { [y]: `${value} ${unit}` }),
        rowSpanLabel,
      };
      Object.entries(priceColumn || {}).forEach(([columnGroupId, columnGroupIdColumn]) => {
        pricesForThisDimension.forEach((price) => {
          row[`${columnGroupId}-${price[xAxis]}`] = {
            diff: price.diff,
            [columnGroupIdColumn]: price[columnGroupIdColumn] ? price[columnGroupIdColumn] : '',
            clientDataRowId: price.rowId,
            hidden: price.hidden,
          };
        });
      });
      rowData.push(row);
    });
  } else {
    [...prices]
      .sort((p1, p2) => {
        const [
          {
            [PricingSheetDimension.Width]: width1,
            [PricingSheetDimension.Length]: length1,
            [PricingSheetDimension.Height]: height1,
          },
          {
            [PricingSheetDimension.Width]: width2,
            [PricingSheetDimension.Length]: length2,
            [PricingSheetDimension.Height]: height2,
          },
        ] = [p1, p2];

        let sort = 0;
        [
          [width1, width2],
          [length1, length2],
          [height1, height2],
        ].forEach(([dim1, dim2]) => {
          if (dim1 !== undefined && dim2 !== undefined && sort === 0 && dim1 - dim2) {
            sort = dim1 - dim2;
          }
        });
        return sort;
      })
      .forEach((price, index) => {
        let rowSpanLabel = '';
        if (index === 0) {
          rowSpanLabel = `${i18n.t(I18nKeys.PricingSheetWidth)} x ${i18n.t(I18nKeys.PricingSheetLength)}`;
        }

        const row: PriceRow = {
          rowId: `${index}`,
          priceY: price.length,
          [y]: `${price.width}x${price.length} ${unit}`,
          rowSpanLabel,
        };

        for (let i = 0; i < regions.length; i += 1) {
          const { priceColumn: regionPriceColumn } = regions[i];
          Object.entries(regionPriceColumn).forEach(([columnGroupId, columnGroupIdColumn]) => {
            row[`${columnGroupId}-${columnGroupIdColumn}`] = {
              diff: price.diff,
              [columnGroupIdColumn]: price[columnGroupIdColumn] ? price[columnGroupIdColumn] : '',
              clientDataRowId: price.rowId,
              hidden: price.hidden,
            };
          });
        }
        rowData.push(row);
      });
  }

  return rowData;
};

const openCantEditDialog = (dispatch: Dispatch<any>) => {
  dispatch(openDialog({ dialog: Dialogs.PricingContactSupport }));
};

/**
 * Gets the table name to be updated for pricing sheet updates
 *
 * @param clientId the client id
 * @param selectedPricingTabId the selected pricing tab id
 * @param categoryKey the selected category key for size based pricing
 * @returns the table name to be updated
 */
export const getPricingSheetTable = (
  clientId: string,
  selectedPricingTabId: string | undefined,
  categoryKey: ClientUpdateCategoryKey | undefined,
) => {
  let table;
  if (selectedPricingTabId === PricingTab.Base) {
    table = clientId.startsWith('shedview') ? 'basePrice' : 'pricingBase';
  }
  if (selectedPricingTabId === PricingTab.SizeBased) {
    switch (categoryKey) {
      case SizeBasedCategoryKey.LegHeight:
        table = 'pricingLegHeight';
        break;
      case SizeBasedCategoryKey.Siding:
        table = 'pricingSiding';
        break;
      case SizeBasedCategoryKey.EndWalls:
        table = 'pricingGableendWall';
        break;
      case SizeBasedCategoryKey.SideWalls:
        table = 'pricingSidewall';
        break;
      case SizeBasedCategoryKey.GableWalls:
        table = 'pricingGable';
        break;
      case SizeBasedCategoryKey.Panels:
        table = 'pricingPanel';
        break;
      default:
        break;
    }
  }
  return table;
};

/**
 * Transforms a standard price column into a siding price column based on the price by quantity
 *
 * @param priceColumn standard price column
 * @param priceByQuantity price by quantity
 * @returns siding price column
 */
export const getSidingPriceColumn = (
  priceColumn: string,
  priceByQuantity: PriceByQuantity,
): keyof PricingSheetPriceData => {
  switch (priceByQuantity) {
    case PriceByQuantity.Each:
      return ((priceColumn === PriceColumn.price && `${priceColumn}Each`) ||
        ((Object.values(PriceColumn) as string[]).includes(priceColumn) && (priceColumn as PriceColumn)) ||
        PriceColumn.price) as keyof PricingSheetPriceData;
    case PriceByQuantity.Combined:
      return `${priceColumn}Combined` as keyof PricingSheetPriceData;
    default:
      return PriceColumn.price;
  }
};

/**
 * Transforms a standard price column into the corresponding wall price columns based on the price by quantity
 *
 * @param priceColumn standard price column
 * @param pricingSheet pricing sheet
 * @param priceByQuantity price by quantity
 * @returns wall price columns
 */
export const getWallPriceColumns = (
  priceColumn: string,
  pricingSheet: PricingSheet | undefined,
  priceByQuantity: PriceByQuantity,
): (keyof PricingSheetPriceData)[] => {
  const { attributes = [] } = pricingSheet || {};
  const sidingDirectionAttribute = attributes.find(({ type }) => type === PricingSheetAttributeType.SidingDirection)
    ?.value as string;

  if (!sidingDirectionAttribute) return [PriceColumn.price];

  return sidingDirectionAttribute
    .split(',')
    .map(
      (direction) =>
        `${direction.trim()}Price${capitalizeFirstLetter(priceByQuantity)}${
          priceColumn !== PriceColumn.price ? capitalizeFirstLetter(priceColumn) : ''
        }` as keyof PricingSheetPriceData,
    );
};

/**
 * Transforms a standard price column into a category price column based on the price by quantity and category
 *
 * @param priceColumn standard price column
 * @param pricingSheet pricing sheet
 * @param priceByQuantity price by quantity
 * @returns category price column
 */
export const getPriceColumnsByCategory = (
  priceColumn: string | undefined,
  pricingSheet: PricingSheet | undefined,
  priceByQuantity: PriceByQuantity,
): (keyof PricingSheetPriceData)[] => {
  const selectedPriceColumn = priceColumn || PriceColumn.price;
  const { category } = pricingSheet || {};

  switch (category) {
    case SizeBasedCategoryKey.Siding:
      return [getSidingPriceColumn(selectedPriceColumn, priceByQuantity)];
    case SizeBasedCategoryKey.EndWalls:
    case SizeBasedCategoryKey.SideWalls:
      return getWallPriceColumns(selectedPriceColumn, pricingSheet, priceByQuantity);
    default:
      return [
        (((Object.values(PriceColumn) as string[]).includes(selectedPriceColumn) &&
          (selectedPriceColumn as PriceColumn)) ||
          PriceColumn.price) as keyof PricingSheetPriceData,
      ];
  }
};

/**
 * Finds the equivalent standard price column for a wall price column.
 *
 * @param wallPriceColumn wall price column
 * @returns standard price column
 */
export const getStandardPriceColumnFromWallPriceColumn = (wallPriceColumn: string) =>
  Object.values(PriceColumn)
    .reverse()
    .find((c) => wallPriceColumn.toLocaleLowerCase().includes(c)) || PriceColumn.price;

/**
 * Finds the equivalent category price column for a different price by quantity.
 *
 * @param newPriceByQuantity new price by quantity
 * @param pricingSheet selected pricing sheet
 * @param priceByQuantityColumn the current price column
 * @returns the price column for the new price by quantity
 */
export const mapPriceByQuantityColumnToPriceColumns = (
  newPriceByQuantity: PriceByQuantity,
  pricingSheet: PricingSheet | undefined,
  priceByQuantityColumn: string,
) =>
  getPriceColumnsByCategory(
    getStandardPriceColumnFromWallPriceColumn(priceByQuantityColumn),
    pricingSheet,
    newPriceByQuantity,
  );

/**
 * Gets any additional updates that need to be made to the row updates based on the category and price by quantity.
 *
 * @param selectedPricingSheet the selected pricing sheet
 * @param columnGroupId the column group id (price by quantity in size based pricing)
 * @param update the update to add additional updates to
 * @returns all updates that need to be made
 */
export const getRowUpdateWithAdditionalUpdates = (
  selectedPricingSheet: PricingSheet | undefined,
  priceByQuantity: PriceByQuantity | undefined,
  columnGroupId: string,
  update: {
    table?: string;
    data: any;
    column: string;
    oldValue: any;
    newValue: any;
  },
): {
  table?: string;
  data: any;
  column: string;
  oldValue: any;
  newValue: any;
}[] => {
  const { oldValue, newValue, column, data: { [ClientDataFixedColumns.RowId]: rowId } = {} } = update;
  const { category = '' } = selectedPricingSheet || {};
  const updates = [];

  if (
    ([SizeBasedCategoryKey.EndWalls, SizeBasedCategoryKey.SideWalls, SizeBasedCategoryKey.Siding] as string[]).includes(
      category,
    )
  ) {
    [columnGroupId, ...(priceByQuantity === PriceByQuantity.Combined ? [PriceByQuantity.Each] : [])].forEach(
      (priceBy) => {
        const columns = mapPriceByQuantityColumnToPriceColumns(
          priceBy as PriceByQuantity,
          selectedPricingSheet,
          column,
        );
        const [oldVal, newVal] = [oldValue, newValue].map((value) =>
          priceByQuantity === PriceByQuantity.Combined && priceBy === PriceByQuantity.Each
            ? `${parseFloat(`${value}`.replace(/[$€£¥₣,]/g, '')) * 0.5}`
            : value,
        );
        columns.forEach((col) => {
          updates.push({
            ...update,
            data: { [ClientDataFixedColumns.RowId]: rowId, [col]: oldVal },
            oldValue: oldVal,
            newValue: newVal,
            column: col,
          });
        });
      },
    );
  } else {
    updates.push(update);
  }

  return updates;
};

/**
 * AG Grid value setter for the pricing data. Handles the pricing value when the user edits a cell.
 *
 * @param clientDataTableId
 * @param pricingSheetTable
 * @param columnGroupId
 * @param priceColumn
 * @param params
 * @param dispatch
 * @param t
 * @param selectedPricingSheet
 * @param priceByQuantity
 * @returns
 */
export const priceColumnValueSetter = (
  clientDataTableId: string,
  pricingSheetTable: string | undefined,
  columnGroupId: string,
  priceColumn: string,
  params: ValueSetterParams,
  dispatch: Dispatch<any>,
  t: Function,
  selectedPricingSheet: PricingSheet,
  priceByQuantity: PriceByQuantity | undefined,
) => {
  try {
    const { data, colDef, oldValue: pOldValue, newValue: pNewValue } = params;
    const oldValue = pOldValue ? `${pOldValue}`.trim() : pOldValue;
    let newValue = typeof pNewValue === 'number' ? pNewValue.toString() : pNewValue;
    const columnId = colDef.colId || params.column.getColId();

    const valueChanged = arePriceValuesDifferent(oldValue, newValue);

    if (!columnId) {
      return false;
    }

    if (!data[columnId] && valueChanged) {
      const configurator = getConfiguratorFromClientId(clientDataTableId.split(':')[1]);
      if (configurator?.key !== SHEDVIEW) {
        openCantEditDialog(dispatch);
        return false;
      }

      newValue = getValidatedNewValue(newValue, oldValue);
      const rowsToAdd = [{ rowId: uuidV1(), width: columnId, length: data.priceY, [priceColumn]: newValue }];
      addDispatchCommandToUndo(
        dispatch,
        [removePricingBaseRows({ rows: rowsToAdd, selectedPricingSheet })],
        [addPricingBaseRow({ rows: rowsToAdd, selectedPricingSheet })],
        clientDataTableId,
        true,
      );
      return true;
    }

    if (valueChanged && colDef.editable) {
      // Data validation. Only allow prices that are valid numbers and greater than 0.
      newValue = getValidatedNewValue(newValue, oldValue);

      data[columnId][priceColumn] = newValue;
      const dataNew = { rowId: data[columnId].clientDataRowId, [priceColumn]: oldValue };

      if (!newValue && !localStorage.getItem(LocalStorage.HaveShownPricingBaseDeletingPriceDialog)) {
        dispatch(openNotificationDialog('', t(I18nKeys.PricingBaseDeletingPriceDialog)));
        dispatch(openDialog({ dialog: Dialogs.Notification }));
        localStorage.setItem(LocalStorage.HaveShownPricingBaseDeletingPriceDialog, '1');
      }

      const rowUpdates = getRowUpdateWithAdditionalUpdates(selectedPricingSheet, priceByQuantity, columnGroupId, {
        table: pricingSheetTable,
        data: dataNew,
        column: priceColumn,
        oldValue,
        newValue,
      });
      const { newRows, oldRows } = processUpdatedValues(rowUpdates, []);
      if (newRows.length > 0) {
        addDispatchCommandToUndo(
          dispatch,
          [updatePricingSheetRows(oldRows)],
          [updatePricingSheetRows(newRows)],
          clientDataTableId,
          true,
        );
      }
    }
    return true;
  } catch (e) {
    console.error(`Failed to set value: `, e);
    return false;
  }
};

export const updateValues = (
  clientDataTableId: string,
  pricingSheetTable: string | undefined,
  updates: { priceColumn: string; data: any; column: string; oldValue: any; newValue: any; colDef: ColDef }[],
  cellMetadata: CellMetadata[],
  dispatch: Dispatch<any>,
) => {
  try {
    const newValues: { table?: string; data: any; column: string; oldValue: any; newValue: any }[] = [];
    updates.forEach(({ priceColumn, data, colDef, column, oldValue: pOldValue, newValue: pNewValue }) => {
      const oldValue = pOldValue ? `${pOldValue}`.trim() : pOldValue;
      let newValue = typeof pNewValue === 'number' ? pNewValue.toString() : pNewValue;
      const columnId = colDef.colId || column;

      const valueChanged = arePriceValuesDifferent(oldValue, newValue);

      if (data[columnId] && valueChanged && colDef.editable) {
        newValue = getValidatedNewValue(newValue, oldValue);
        const dataNew = { rowId: data[columnId].clientDataRowId, [priceColumn]: oldValue };
        newValues.push({ table: pricingSheetTable, data: dataNew, column: priceColumn, oldValue, newValue });
      }
    });

    const { newRows, oldRows } = processUpdatedValues(newValues, cellMetadata);
    if (newRows.length > 0) {
      addDispatchCommandToUndo(
        dispatch,
        [updatePricingSheetRows(oldRows)],
        [updatePricingSheetRows(newRows)],
        clientDataTableId,
        true,
      );
    }
    return true;
  } catch (e) {
    console.error(`Failed to set value: `, e);
    return false;
  }
};

export const deleteRows = (
  clientDataTableId: string,
  rowIds: string[],
  selectedPricingSheet: PricingSheet,
  dispatch: Dispatch<AnyAction>,
  t: Function,
): void => {
  const rowsToRemoveSet = new Set<PriceRow>();
  for (let i = 0; i < rowIds.length; i += 1) {
    const price = selectedPricingSheet?.prices?.find((r) => r.rowId === rowIds[i]);
    if (price) {
      rowsToRemoveSet.add(price);
    }
  }
  const rowsToRemove = Array.from(rowsToRemoveSet);

  if (rowsToRemove.length === selectedPricingSheet?.prices?.length) {
    dispatch(
      openNotificationDialog(
        t(I18nKeys.PricingBaseDeletingAllPricesDialogTitle),
        t(I18nKeys.PricingBaseDeletingAllPricesDialogMessage),
      ),
    );
    dispatch(openDialog({ dialog: Dialogs.Notification }));
    return;
  }

  if (rowsToRemove.length > 0) {
    addDispatchCommandToUndo(
      dispatch,
      [addPricingBaseRow({ rows: rowsToRemove, selectedPricingSheet })],
      [removePricingBaseRows({ rows: rowsToRemove, selectedPricingSheet })],
      clientDataTableId,
      true,
    );
  }
};

export const askToDeleteRows = (
  cellsFromSelectedRange: string[],
  pricingSheet: PricingSheet,
  clientDataTableId: string,
  dispatch: Dispatch<AnyAction>,
  t: Function,
  gridApi: GridApi,
) => {
  let basePrice;
  const cellsSelectedCount = cellsFromSelectedRange.length;
  if (cellsSelectedCount === 1) {
    basePrice = pricingSheet?.prices?.find((p) => p.rowId === cellsFromSelectedRange[0]);
  }

  const priceSheetTitle =
    pricingSheet?.priceSetLabel ||
    t(I18nKeys.PricingBaseAccordionPricingSheetTitle, { pricingSheetId: pricingSheet.id });

  dispatch(
    openConfirmationDialog(
      undefined,
      t(I18nKeys.PricingBaseDeletePriceConfirmationDialogMessage, {
        prices: t(I18nKeys.PricingBaseDeletePriceConfirmationDialogMessageSizes, {
          size: basePrice
            ? t(I18nKeys.PricingBaseDeletePriceConfirmationDialogSize, {
                width: basePrice.width,
                length: basePrice.length,
              })
            : cellsSelectedCount,
          count: cellsSelectedCount,
        }),
        priceSheetTitle,
      }),
      t(I18nKeys.DialogCancelButton),
      [
        {
          label: t(I18nKeys.DialogDeleteButton),
          onClick: () => {
            dispatch(closeDialog());
            clearSelections(gridApi);
            deleteRows(clientDataTableId, cellsFromSelectedRange, pricingSheet, dispatch, t);
          },
        },
      ],
    ),
  );
  dispatch(openDialog({ dialog: Dialogs.Confirmation }));
};

export const removePrices = (
  clientDataTableId: string,
  pricingSheetTable: string | undefined,
  nodes: any[],
  dataColumn: PriceColumnsByGroupId | undefined,
  dispatch: Dispatch<any>,
) => {
  try {
    const updatedRows: {
      table?: string;
      data: any;
      column: string;
      oldValue: any;
      newValue: any;
    }[] = [];
    for (let i = 0; i < nodes.length; i += 1) {
      const node = nodes[i];
      if (node) {
        const { data } = node;
        if (data) {
          (dataColumn ? Object.values(dataColumn) : [node.priceColumn]).forEach((priceColumn) => {
            const dataNew = { rowId: data.clientDataRowId, [priceColumn]: data[priceColumn] };
            const { columnId } = getColumnId(priceColumn);
            updatedRows.push({
              table: pricingSheetTable,
              data: dataNew,
              column: columnId,
              oldValue: data[priceColumn],
              newValue: '',
            });
          });
        }
      }
    }
    const { newRows, oldRows } = processUpdatedValues(updatedRows, []);

    if (newRows.length > 0) {
      addDispatchCommandToUndo(
        dispatch,
        [updatePricingSheetRows(oldRows)],
        [updatePricingSheetRows(newRows)],
        clientDataTableId,
        true,
      );
    }
  } catch (e) {
    console.error(`Failed to remove prices: `, e);
  }
};

/**
 * AG Grid formatter for the pricing data. Handles the currency to display
 *
 * @param params
 * @param priceColumn
 * @param formatPriceWithDecimal
 * @param regionKey
 * @param currency
 * @returns
 */
export const priceColumnFormatter = (
  params: ValueFormatterParams,
  priceColumn: keyof PricingSheetPriceData,
  formatPriceWithDecimal: boolean,
  regionKey: string,
  currency?: string,
) => {
  const {
    colDef: { colId = '' },
    data,
  } = params;
  const { [colId]: value } = data;

  let price;
  let minimumFractionDigits = 0;
  if (colId) {
    price = value ? value[priceColumn] : '';
    if (formatPriceWithDecimal && price) {
      minimumFractionDigits = 2;
    }
  }

  const formattedPrice = price ? formatPrice(price, currency, minimumFractionDigits) : '';
  if (value?.hidden[regionKey]) return i18n.t(I18nKeys.PricingBaseHiddenPrice);
  return formattedPrice;
};

/**
 * AG Grid value getter for the pricing data
 *
 * @param params
 * @param priceColumn
 * @returns
 */
export const pricingColumnValueGetter = (params: ValueGetterParams, priceColumn: keyof PricingSheetPriceData) => {
  const { data, colDef } = params;
  const { colId = '' } = colDef;

  let price;
  if (data[colId]) {
    price = data[colId] ? data[colId][priceColumn] : '';
  }
  return price || '';
};

/**
 * Gets an array of client data row ids from the selected cell range
 *
 * @param gridApi
 * @returns
 */
export const getClientDataRowIdsFromSelectedRange = (gridApi: GridApi): string[] => {
  const cellRanges = gridApi.getCellRanges();
  if (!cellRanges || !cellRanges.length) return [];
  const { startRowIndex, endRowIndex, columns } = getCellRangeInfo(cellRanges);
  if (startRowIndex === undefined || endRowIndex === undefined) return [];

  const numRows = Math.abs(endRowIndex - startRowIndex) + 1;
  const clientDataRowIdsfromSelection = [] as any[];
  for (let i = 0; i < numRows; i += 1) {
    columns.forEach((column) => {
      const row = gridApi.getDisplayedRowAtIndex(i + startRowIndex)?.data[column]?.clientDataRowId;
      if (row) {
        clientDataRowIdsfromSelection.push(row);
      }
    });
  }
  return clientDataRowIdsfromSelection;
};

/**
 * Update the cell metadata for a cell
 *
 * @param clientDataTableId
 * @param updates
 * @param dispatch
 */
export const updateCellMetadata = (
  clientDataTableId: string,
  updates: UpdateClientDataMetadata[],
  dispatch: Dispatch<any>,
) => {
  const [{ cellsMetadata = [] } = {}] = updates;

  const undoUpdates = updates.map((update) => ({
    ...update,
    value: getPropertyFromCellMetadata(cellsMetadata, update.rowId, update.colId, update.metadataProperty),
  }));

  addDispatchCommandToUndo(
    dispatch,
    [updatePricingMetadata(undoUpdates)],
    [updatePricingMetadata(updates)],
    clientDataTableId,
    true,
  );
};

/**
 * Processes the data from the grid to the clipboard
 *
 * @param clientDataTableId
 * @param params Process Data From Clipboard event params
 * @param param1 Cell metadata and dispatch function
 * @returns
 */
export const processDataFromClipboard = (
  params: ProcessDataFromClipboardParams,
  {
    clientDataTableId,
    pricingSheetTable,
    gridViewType,
    regions,
    getPriceColumn,
    dispatch,
  }: {
    clientDataTableId: string;
    pricingSheetTable: string | undefined;
    gridViewType: GridViewType;
    regions: PricingSheetRegion[] | undefined;
    getPriceColumn: (colId: string, colGroupId: string) => keyof PricingSheetPriceData;
    dispatch: Dispatch<any>;
  },
) => {
  const { t } = i18n;
  const {
    data: cellMatrix,
    api,
    columnApi,
    context: { regionKey: region },
  } = params;
  const { startRowIndex, columns: selectedColumns } = getCellRangeInfo(api.getCellRanges());

  // Columns must be ordered for correct index
  const allColumnsOrdered = (columnApi?.getAllGridColumns() || [])
    .map((col) => col.getColId())
    // Sort so that columns pinned left are first, then unpinned, then pinned right
    .sort((a, b) => {
      const [aOrder, bOrder] = [a, b].map((col) => {
        const column = columnApi?.getColumn(col);
        if (column?.isPinnedLeft()) return -1;
        if (column?.isPinnedRight()) return 1;
        return 0;
      });
      return aOrder - bOrder;
    });
  const selectedColumnsOrdered = allColumnsOrdered.filter((col) => selectedColumns.includes(col));
  const [firstColumn] = selectedColumnsOrdered;
  const columnStartIndex = allColumnsOrdered.indexOf(firstColumn);

  if (startRowIndex === undefined || columnStartIndex === -1) return null;

  const newRows: UpdateClientDataRow[] = [];
  const oldRows: UpdateClientDataRow[] = [];

  const clipboardDataMatrix = JSON.parse(
    localStorage.getItem(LocalStorage.ClientDataClipboardData) || '[]',
  ) as ClipboardData[][];

  let gridDataMatrix = cellMatrix;
  // After joining with delimiters, compare the grid data to the clipboard data
  const joinedGridData = gridDataMatrix.map((row) => row.join(COLUMN_DELIMITER)).join(ROW_DELIMITER);
  const joinedClipboardData = clipboardDataMatrix
    .map((row) => row.map((cell) => cell.value).join(COLUMN_DELIMITER))
    .join(ROW_DELIMITER);
  if (joinedGridData === joinedClipboardData) {
    // If the data is the same, use the clipboard data matrix to get rid of any extra rows/columns
    // from \t and \n characters
    if (
      gridDataMatrix.length !== clipboardDataMatrix.length ||
      gridDataMatrix.every((row, i) => row.length !== clipboardDataMatrix[i].length)
    ) {
      gridDataMatrix = clipboardDataMatrix.map((row) => row.map((cell) => cell.value));
    }
  }

  Array.from({ length: gridDataMatrix.length }).forEach((_el, i) => {
    // Find the row where the data will be pasted
    const { id: rowId, data } = api.getDisplayedRowAtIndex(startRowIndex + i) || {};

    // If the row doesn't exist, ignore it
    if (!rowId || data.rowIsReadOnly) return null;

    // Repeat the cell matrix if there are more rows than cells
    const [row] = [gridDataMatrix, clipboardDataMatrix].map((matrix) => matrix[i % matrix.length] || []);

    return (row as string[]).forEach((value, j) => {
      // If ran out columns, ignore this value
      if (columnStartIndex + j >= allColumnsOrdered.length) return;

      const columnIdAndColumnGroupId = allColumnsOrdered[columnStartIndex + j];
      const { columnGroupId, columnId } = getColumnAndColumnGroupId(columnIdAndColumnGroupId);
      const priceCol = getPriceColumn(columnId, columnGroupId);
      const cellRegionKey =
        gridViewType === GridViewType.List
          ? regions?.find(({ priceColumn }) => Object.values(priceColumn).some((col) => col === columnId))?.regionKey
          : region;

      // Don't add if there isn't a valid data entry for this column or if the value isinvalid or if the cell is hidden
      if (
        !data[columnIdAndColumnGroupId] ||
        value === undefined ||
        value === null ||
        data[columnIdAndColumnGroupId].hidden[cellRegionKey]
      )
        return;

      const oldValue = data[columnIdAndColumnGroupId][priceCol];
      const validatedValue = getValidatedNewValue(value, oldValue);

      oldRows.push({
        table: pricingSheetTable,
        rowData: {
          [priceCol]: data[columnIdAndColumnGroupId][priceCol],
          rowId: data[columnIdAndColumnGroupId].clientDataRowId,
        },
        column: priceCol,
        value: data[columnIdAndColumnGroupId][priceCol],
        formula: undefined,
      });
      newRows.push({
        table: pricingSheetTable,
        rowData: {
          [priceCol]: data[columnIdAndColumnGroupId][priceCol],
          rowId: data[columnIdAndColumnGroupId].clientDataRowId,
        },
        column: priceCol,
        value: validatedValue,
        formula: undefined,
      });
    });
  });

  const completePaste = () =>
    addDispatchCommandToUndo(
      dispatch,
      [updatePricingSheetRows(oldRows)],
      [updatePricingSheetRows(newRows)],
      clientDataTableId,
      true,
    );

  const endRowIndex = (api?.getModel().getRowCount() || 0) - 1;
  if (
    gridDataMatrix.length > endRowIndex - startRowIndex + 1 ||
    gridDataMatrix[0].length > allColumnsOrdered.slice(columnStartIndex).length
  ) {
    dispatch(
      openConfirmationDialog(undefined, t(I18nKeys.PricingBaseClipboardExceedsSpaceWarning), undefined, [
        {
          label: t(I18nKeys.PasteAnywayButton),
          onClick: completePaste,
        },
      ]),
    );
    dispatch(openDialog({ dialog: Dialogs.Confirmation }));
  } else {
    completePaste();
  }
  return null;
};

/**
 * Handles sheet name updates, updating each row in the selected sheet with the new name
 * The priceSetLabel column holds the sheet name value.
 *
 * @param title - the new title for this sheet
 * @param pricingSheetTable - the table to update
 * @param selectedPricingSheet - the sheet that is being operated on
 * @param dispatch
 * @returns
 */
export const updateSheetTitle = (
  title: string,
  pricingSheetTable: string | undefined,
  selectedPricingSheet: PricingSheet,
  dispatch: Dispatch<any>,
) => {
  const newRows: UpdateClientDataRow[] = [];
  const priceSetColumns = getPriceSetColumns(selectedPricingSheet);

  const { prices = [] } = selectedPricingSheet;
  prices.forEach((price) => {
    priceSetColumns.forEach((priceSetColumn) => {
      newRows.push({
        table: pricingSheetTable,
        rowData: { [priceSetColumn]: selectedPricingSheet.priceSetLabel, rowId: price.rowId },
        column: priceSetColumn,
        value: title,
        formula: undefined,
      });
    });
  });

  dispatch(updatePricingSheetRows(newRows));
};

/**
 * Gets a column definition for the y-axis span header
 *
 * @param y the y-axis dimension
 * @param rowsToSpan number of rows to span
 * @returns
 */
export const getRowSpanHeader = (y: PricingSheetDimension, rowsToSpan: number): ColDef => {
  const width = getRowSpanHeaderWidth(y, rowsToSpan);

  return {
    ...defaultColumn,
    cellStyle: { fontWeight: 'bold' },
    pinned: 'left' as ColumnPinned,
    editable: false,
    headerName: '',
    headerComponent: SelectAllCellsHeader,
    colId: DefaultPricingColumnFieldNames.RowSpanLabel,
    field: DefaultPricingColumnFieldNames.RowSpanLabel,
    width,
    suppressNavigable: true,
    headerTooltip: '',
    rowSpan: (params) => {
      const idx = params.node?.rowIndex || 1;
      const result = rowsToSpan - idx;
      return result;
    },
    cellClassRules: {
      'ag-grid-pricing-row-span-label': (params) => params?.value,
      'ag-grid-pricing-row-span-label-vertical': () => rowsToSpan > 3,
      'ag-grid-pricing-row-span-label-horizontal': () => rowsToSpan <= 3,
    },
  };
};

/**
 * Gets a column definition for the y-axis label column
 *
 * @param y the y-axis dimension
 * @param gridViewType type of grid view type being displayed
 * @returns
 */
export const getYAxisDimensionColumnDef = (y: PricingSheetDimension, clientGridViewType: GridViewType): ColDef => ({
  ...defaultColumn,
  cellClassRules: { 'ag-grid-index-column': () => true },
  cellStyle: { fontWeight: 'bold', paddingRight: '6px', fontSize: '13px' },
  headerName: '',
  editable: false,
  headerTooltip: '',
  headerComponent: SelectAllCellsHeader,
  field: y,
  colId: y,
  initialWidth: clientGridViewType === GridViewType.Grid ? lengthColumnWidth : widthLengthColumnWidth,
});

/**
 * Autosizes the y-axis dimensioncolumn width
 *
 * @param columnApi AG Grid column api
 */
export const updateYAxisDimensionColumnWidth = (y: PricingSheetDimension, columnApi?: ColumnApi) => {
  columnApi?.getColumns()?.forEach((col) => {
    const colId = col.getColDef().field;
    const columns = [];
    if (colId === y) {
      columns.push(col);
    }
    if (columns.length > 0) {
      columnApi?.autoSizeColumns(columns);
    }
  });
};

/**
 * Determines whether the pricing sheet should be formatted with decimal places
 *
 * @param pricingSheet pricing sheet to check
 * @param regions all regions for the vendor
 * @param priceColumn the price column to check
 * @returns whether the pricing sheet should be formatted with decimal places
 */
export const formatPriceWithDecimal = (
  pricingSheet: PricingSheet | undefined,
  regions: PricingSheetRegion[] = [],
  priceColumn: PriceColumnsByGroupId | undefined,
) => {
  const { prices = [] } = pricingSheet || {};
  return !!prices.find((price) => {
    if (regions) {
      return regions.some(({ priceColumn: regionPriceColumn }) =>
        Object.values(regionPriceColumn).some((col) => isDecimalPrice(price[col])),
      );
    }
    return Object.values(priceColumn || {}).some((col) => isDecimalPrice(price[col]));
  });
};

/**
 * Gets the width of the currency symbol text
 *
 * @param currency vendor currency
 * @returns
 */
const getCurrencyWidth = (currency: string) => {
  const currencySymbol = getCurrencySymbol(currency);
  return currencySymbol !== '$'
    ? getTextWidth(currencySymbol, 'calc(var(--ag-font-size) + 1px) var(--ag-font-family)')
    : 0;
};

/**
 *  Updates the price column widths to reflect the width of the currency text
 *
 * @param currency vendor currency
 * @param columnApi AG Grid column api
 * @param decimalFormat whether the price is in decimal format
 */
export const updatePriceColumnWidths = (currency: string, columnApi: ColumnApi | undefined, decimalFormat: boolean) => {
  const columnState = columnApi?.getColumnState();
  const currencyWidth = getCurrencyWidth(currency);

  columnState?.forEach((col) => {
    const { colId } = col;
    if (
      [DefaultPricingColumnFieldNames.RowSpanLabel, ...Object.values(PricingSheetDimension)].includes(
        colId as DefaultPricingColumnFieldNames,
      )
    ) {
      return;
    }
    if (decimalFormat) {
      columnApi?.setColumnWidth(colId, decimalPriceColumnWidth + currencyWidth);
    } else {
      columnApi?.setColumnWidth(colId, priceColumnWidth + currencyWidth);
    }
  });
};

/**
 * Gets column definitions for pricing sheet grids with the list view
 *
 * @param clientDataTableId the client data table id
 * @param pricingSheetTable the pricing sheet table
 * @param selectedPricingSheet the selected pricing sheet
 * @param regions all regions for the vendor
 * @param defaultPriceColumn the default price column object
 * @param dispatch redux dispatch function
 * @param t i18n translation function
 * @param getCellClass function to get the cell class
 * @param useDecimalFormat whether to format the price with decimal places
 * @param groupingCellUpdates whether to group cell updates
 * @param availablePricePerRegion available prices per region
 * @param onGroupUpdateEdit function for group update edit
 * @param currency vendor currency
 * @param hasDefaultRegion whether the vendor has a default region
 * @returns column definitions for the pricing sheet grid
 */
export const getListGridViewColDefs = (
  clientDataTableId: string,
  pricingSheetTable: string | undefined,
  selectedPricingSheet: PricingSheet,
  regions: PricingSheetRegion[],
  defaultPriceColumn: PriceColumnsByGroupId,
  dispatch: Dispatch<any>,
  t: Function,
  getCellClass: (cellClassParam: CellClassParams<PriceRow>) => string[],
  useDecimalFormat: boolean,
  groupingCellUpdates: boolean,
  availablePricePerRegion: Map<string, PricesPerRegionDiff>,
  onGroupUpdateEdit?: Function,
  currency?: string,
  priceByQuantity?: PriceByQuantity,
  hasDefaultRegion = false,
) => {
  const defs: ColDef[] = [];
  const onlyOneRegion = regions.length === 1;
  const { prices = [] } = selectedPricingSheet;

  regions.forEach((region) => {
    const { regionKey, label, priceColumn } = region;
    const isHiddenRegion = !!prices?.every(({ hidden }) => hidden[regionKey]);

    Object.entries(priceColumn).forEach(([columnGroupId, columnGroupIdColumn]) => {
      defs.push({
        headerName: !onlyOneRegion
          ? label
          : t(I18nKeys.PricingBaseDefaultRegionHeaderListViewLabel, { defaultValue: label }),
        field: `${columnGroupId}-${columnGroupIdColumn}`,
        colId: `${columnGroupId}-${columnGroupIdColumn}`,
        valueGetter: (params: ValueGetterParams) => pricingColumnValueGetter(params, columnGroupIdColumn),
        valueFormatter: (params: ValueFormatterParams) =>
          priceColumnFormatter(params, columnGroupIdColumn, useDecimalFormat, regionKey, currency),
        valueSetter: (params: ValueSetterParams) =>
          groupingCellUpdates && onGroupUpdateEdit
            ? onGroupUpdateEdit(params)
            : priceColumnValueSetter(
                clientDataTableId,
                pricingSheetTable,
                columnGroupId,
                columnGroupIdColumn,
                params,
                dispatch,
                t,
                selectedPricingSheet,
                priceByQuantity,
              ),
        cellClass: getCellClass,
        headerTooltip: `${label}${
          hasDefaultRegion
            ? `\n${getSubtitleText(availablePricePerRegion, prices.length, region, defaultPriceColumn, isHiddenRegion)}`
            : ''
        }`,
        tooltipComponent: PricingGridTooltip,
        tooltipValueGetter: (params) => {
          const columnId = (params.column as Column).getColId();
          const { clientDataRowId } = params.data[columnId] || '';
          const { cellMetadata } = params.context;
          if (!hasCellMetadataProperty(cellMetadata, clientDataRowId, columnGroupIdColumn, CellMetadataProperty.Note))
            return '';
          const note = getPropertyFromCellMetadata(
            cellMetadata,
            clientDataRowId,
            columnGroupIdColumn,
            CellMetadataProperty.Note,
          );
          return note;
        },
        editable: ({ column, data }) => {
          const colId = column?.getColId();
          return !data[colId]?.hidden[regionKey];
        },
        suppressFillHandle: isHiddenRegion,
      });
    });
  });
  return defs;
};

export const getColumnGroupHeader = (columnGroupId: string, t: Function) => {
  switch (columnGroupId) {
    case PriceByQuantity.Each:
      return t(I18nKeys.PricingSheetColumnGroupHeaderEach);
    case PriceByQuantity.Combined:
      return t(I18nKeys.PricingSheetColumnGroupHeaderCombined);
    default:
      return columnGroupId;
  }
};

/**
 *  Gets column definitions for pricing sheet grids with the standard grid view
 *
 * @param clientDataTableId the client data table id
 * @param pricingSheetTable the pricing sheet table
 * @param selectedPricingSheet the selected pricing sheet
 * @param regions all regions for the vendor
 * @param priceColumn the price column object the grid is displaying
 * @param x the x-axis dimension
 * @param allDimensionValues all unique dimension values for the x-axis
 * @param dispatch redux dispatch function
 * @param t i18n translation function
 * @param getCellClass function to get the cell class
 * @param unit the unit of the x-axis dimension
 * @param useDecimalFormat whether to format the price with decimal places
 * @param groupingCellUpdates whether to group cell updates
 * @param onGroupUpdateEdit function for group update edit
 * @param currency vendor currency
 * @returns column definitions for the pricing sheet grid
 */
export const getXAxisDimensionColDefs = (
  clientDataTableId: string,
  pricingSheetTable: string | undefined,
  selectedPricingSheet: PricingSheet,
  regions: PricingSheetRegion[],
  priceColumn: PriceColumnsByGroupId,
  x: PricingSheetDimension,
  allDimensionValues: string[],
  dispatch: Dispatch<any>,
  t: Function,
  getCellClass: (cellClassParam: CellClassParams<PriceRow>) => string[],
  unit: string,
  useDecimalFormat: boolean,
  groupingCellUpdates: boolean,
  onGroupUpdateEdit?: Function,
  currency?: string,
  priceByQuantity?: PriceByQuantity,
) => {
  const regionKey = regions[0]?.regionKey;
  const { prices } = selectedPricingSheet;
  const isHiddenRegion = !!prices?.every(({ hidden }) => hidden[regionKey]);

  const columnGroups = Object.entries(priceColumn).reduce(
    (acc, [columnGroupId, columnGroupIdColumn]) => ({
      ...acc,
      [columnGroupId]: allDimensionValues.map((dimension) => ({
        headerName: `${dimension} ${unit}`,
        colId: `${columnGroupId}-${dimension}`,
        valueGetter: (params: ValueGetterParams) => pricingColumnValueGetter(params, columnGroupIdColumn),
        valueFormatter: (params: ValueFormatterParams) =>
          priceColumnFormatter(params, columnGroupIdColumn, useDecimalFormat, regionKey, currency),
        valueSetter: (params: ValueSetterParams) =>
          groupingCellUpdates && onGroupUpdateEdit
            ? onGroupUpdateEdit(params)
            : priceColumnValueSetter(
                clientDataTableId,
                pricingSheetTable,
                columnGroupId,
                columnGroupIdColumn,
                params,
                dispatch,
                t,
                selectedPricingSheet,
                priceByQuantity,
              ),
        cellClass: getCellClass,
        tooltipComponent: ClientDataTooltip,
        tooltipValueGetter: (params: ITooltipParams) => {
          const columnId = (params.column as Column).getColId();
          const { clientDataRowId } = params.data[columnId] || '';
          const { cellMetadata } = params.context;
          if (!hasCellMetadataProperty(cellMetadata, clientDataRowId, columnGroupIdColumn, CellMetadataProperty.Note))
            return '';
          const note = getPropertyFromCellMetadata(
            cellMetadata,
            clientDataRowId,
            columnGroupIdColumn,
            CellMetadataProperty.Note,
          );
          return note;
        },
        editable: ({ column, data }: EditableCallbackParams) => {
          const colId = column?.getColId();
          return !data[colId]?.hidden[regionKey];
        },
        suppressFillHandle: isHiddenRegion,
      })),
    }),
    {} as Record<string, ColDef[]>,
  );

  if (Object.keys(columnGroups).length === 1) {
    return [
      {
        headerName: getDimensionHeaderLabel(x, t),
        headerClass: ['ag-grid-x-dimension-header-column'],
        children: Object.values(columnGroups)[0],
      },
    ];
  }

  return Object.entries(columnGroups).map(([columnGroupId, columnGroup]) => ({
    headerName: getColumnGroupHeader(columnGroupId, t),
    headerClass: ['ag-grid-x-dimension-header-column'],
    groupId: columnGroupId,
    children: [
      {
        headerName: getDimensionHeaderLabel(x, t),
        headerClass: ['ag-grid-x-dimension-header-column'],
        children: columnGroup,
      },
    ],
  }));
};

/**
 * Gets the grid x and y dimensions for a pricing sheet based on the pricing tab and grid view type.
 *
 * @param pricingTab the pricing tab currently being viewed
 * @param category the category currently being viewed
 * @param gridViewType the grid view type currently being used or selected
 * @returns the grid x and y dimensions for a pricing sheet
 */
export const getPricingSheetDimensions = (
  pricingTab: PricingTab,
  category: ClientUpdateCategoryKey | undefined,
  gridViewType: GridViewType,
) => {
  if (pricingTab === PricingTab.SizeBased) {
    switch (category) {
      case SizeBasedCategoryKey.EndWalls:
        return pricingSizeBasedWidthGridView;
      case SizeBasedCategoryKey.Panels:
        return pricingSizeBasedPanelWidthGridView;
      case SizeBasedCategoryKey.GableWalls:
        return pricingSizeBasedWidthOnlyGridView;
      default:
        return pricingSizeBasedLengthGridView;
    }
  }
  return gridViewType === GridViewType.Grid ? pricingBaseGridView : pricingBaseListView;
};

/**
 * Gets the different label parts for a pricing sheet that are generally displayed as bullet points.
 * If a priceSetLabel is available, that will be the only label part.
 *
 * @param pricingSheet the pricing sheet to get the label parts for
 * @param pricingTab the pricing tab currently being viewed
 * @param t i18n translation function
 * @returns the different label parts for a pricing sheet
 */
export const getPricingSheetLabelParts = (
  { priceSetLabel, category, attributes }: PricingSheet,
  pricingTab: string,
  t: TFunction,
) => {
  const parts = [
    ...(category === SizeBasedCategoryKey.Siding ? [t(I18nKeys.PricingSheetAttributeSidingUpcharge)] : []),
    ...[
      priceSetLabel ||
        attributes
          .filter((attribute) => !attribute.hide)
          .map((attribute) => getAttributeLabel(pricingTab, attribute, t)),
    ]
      .flat()
      .filter(Boolean)
      .sort((a, b) => (pricingTab !== PricingTab.SizeBased ? (a || '').localeCompare(b || '') : 0)),
  ];
  return parts;
};

/**
 * Gets the default label for a pricing sheet based on the attributes when there is no priceSetLabel.
 *
 * @param pricingSheet the pricing sheet
 * @param pricingTab the pricing tab currently being viewed
 * @returns the default label for the pricing sheet
 */
export const getPricingSheetDefaultLabel = (pricingSheet: PricingSheet | undefined, pricingTab: string) => {
  if (pricingTab === PricingTab.Base) return '';

  const { attributes = [] } = pricingSheet || {};
  return attributes
    .filter(
      ({ type, hide, label }) =>
        !hide && label && !([PricingSheetAttributeType.CustomExpression] as string[]).includes(type),
    )
    .map(({ label }) => label)
    .sort((a, b) => a.localeCompare(b))
    .join(', ');
};

/**
 * Transforms a list of regions into a list of pricing sheet regions by creating a price column object
 * containing a price column to be used for each price by quantity option
 *
 * @param selectedPricingSheet selected pricing sheet
 * @param regions regions for the vendor
 * @param priceByQuantities price by quantity options
 * @returns list of pricing sheet regions
 */
export const getPricingSheetRegions = (
  selectedPricingSheet: PricingSheet | undefined,
  regions: Region[],
  priceByQuantities: PriceByQuantity[],
) =>
  regions.map(({ priceColumn, ...otherProps }) => ({
    ...otherProps,
    priceColumn: priceByQuantities.reduce((acc, columnGroupId) => {
      [acc[columnGroupId]] = getPriceColumnsByCategory(priceColumn, selectedPricingSheet, columnGroupId);
      return acc;
    }, {} as Record<string, keyof PricingSheetPriceData>),
  }));

/**
 * Performs updates to the pricing sheet based on the price by quantity change.
 * If using calculate values, the values will be recalculated based on the new price by quantity.
 * Ex: If moving from each to combined, the values will be doubled. If moving from combined to each, the values will be halved.
 *
 * @param pricingSheetTable the pricing sheet table
 * @param dispatch redux dispatch function
 * @param oldPriceByQuantity the current price by quantity
 * @param newPriceByQuantity the new price by quantity
 * @param pricingSheet the selected pricing sheet
 * @param regions the regions for the vendor
 * @param calculateValues whether to calculate values based on the new price by quantity
 * @returns updates to the pricing sheet
 */
export const getUpdatesForPriceByQuantityChange = (
  pricingSheetTable: string | undefined,
  oldPriceByQuantity: PriceByQuantity,
  newPriceByQuantity: PriceByQuantity,
  pricingSheet: PricingSheet | undefined,
  regions: PricingSheetRegion[],
  calculateValues: boolean,
) => {
  // Make no changes if moving to split view
  if (oldPriceByQuantity === newPriceByQuantity) {
    return [];
  }

  const getOppositeColumn = (quantityType: PriceByQuantity) => {
    switch (quantityType) {
      case PriceByQuantity.Each:
        return PriceByQuantity.Combined;
      case PriceByQuantity.Combined:
        return PriceByQuantity.Each;
      case PriceByQuantity.Split:
      default:
        return quantityType;
    }
  };

  const [newQuantityColumns, oppositeQuantityColumns] = [newPriceByQuantity, getOppositeColumn(newPriceByQuantity)].map(
    (quantity) =>
      quantity !== PriceByQuantity.Split
        ? Array.from(
            new Set(
              regions.flatMap(({ priceColumn }) =>
                mapPriceByQuantityColumnToPriceColumns(quantity, pricingSheet, Object.values(priceColumn)[0]),
              ),
            ),
          )
        : [],
  );

  const { prices = [] } = pricingSheet || {};
  return prices.reduce((pricingSheetNewRows, price) => {
    const priceNewRows = oppositeQuantityColumns.reduce((newRows, col, i) => {
      const newCol = newQuantityColumns[i];
      const [oppositePrice, currentPrice] = [col, newCol].map((c) => {
        const parsedPrice = parseFloat(removeCurrencySymbolsCommasAndSpaces(price[c]));
        return !Number.isNaN(parsedPrice) ? parsedPrice : null;
      });

      const multiplyBy = calculateValues ? (newPriceByQuantity === PriceByQuantity.Combined && 2) || 0.5 : 1;
      let newPriceByQuantityPrice = oppositePrice;
      if (oldPriceByQuantity === PriceByQuantity.Split) {
        // The new price for the new quantity shouldn't change if moving from split to each or combined
        newPriceByQuantityPrice = currentPrice;
      } else if (newPriceByQuantityPrice !== null) {
        newPriceByQuantityPrice *= multiplyBy;
      }

      let newOppositePrice = null;
      if (newPriceByQuantity === PriceByQuantity.Combined && newPriceByQuantityPrice !== null) {
        // If moving to combined, make sure the each price is half the combined price
        newOppositePrice = newPriceByQuantityPrice * 0.5;
      }
      if (`${oppositePrice}` !== `${newOppositePrice}`) {
        newRows.push({
          table: pricingSheetTable,
          rowData: { [ClientDataFixedColumns.RowId]: price[ClientDataFixedColumns.RowId], [col]: price[col] },
          column: col,
          value: newOppositePrice !== null ? `${newOppositePrice}` : null,
          formula: undefined,
        });
      }

      // When moving to each or combined, set the new quantity column and optionally multiply by 2 or 0.5
      if (`${newPriceByQuantityPrice}` !== `${currentPrice}`) {
        newRows.push({
          table: pricingSheetTable,
          rowData: { [ClientDataFixedColumns.RowId]: price[ClientDataFixedColumns.RowId], [newCol]: price[newCol] },
          column: newCol,
          value: newPriceByQuantityPrice !== null ? `${newPriceByQuantityPrice}` : null,
          formula: undefined,
        });
      }
      return newRows;
    }, [] as UpdateClientDataRow[]);
    return [...pricingSheetNewRows, ...priceNewRows];
  }, [] as UpdateClientDataRow[]);
};
