/* eslint-disable no-param-reassign */
import { GridColumnsInitialState, GridFilterModel, GridPinnedColumnFields } from '@mui/x-data-grid-premium';
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { MUIDataGridState } from '../types/MUIDataGridState';
import { DateRange } from '../types/DateRange';
import { getLastDaysDateRange } from '../utils/dateUtils';

const initialState: MUIDataGridState = {
  dateRangeOptionId: 0,
  dateRangeFilter: getLastDaysDateRange(45),
  filterModel: undefined,
};

export const updateDataGridState = createAction<{
  dataGridKey: string;
  pinnedColumns?: GridPinnedColumnFields | undefined;
  columns?: GridColumnsInitialState | undefined;
}>('muiDataGrid/updateDataGridState');

export const muiDataGridSlice = createSlice({
  name: 'muiDataGrid',
  initialState,
  reducers: {
    setDateRangeFilter(
      state,
      action: PayloadAction<{
        dateRangeFilter: DateRange;
      }>,
    ) {
      state.dateRangeFilter = action.payload.dateRangeFilter;
    },
    setDateRangeOptionId(state, action: PayloadAction<number>) {
      state.dateRangeOptionId = action.payload;
    },
    setFilterModel(state, action: PayloadAction<GridFilterModel>) {
      state.filterModel = action.payload;
    },
  },
});

export const { setDateRangeFilter, setDateRangeOptionId, setFilterModel } = muiDataGridSlice.actions;
