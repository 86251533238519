import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { I18nKeys } from '../constants/I18nKeys';
import { ConfirmationButton, ConfirmationState } from '../types/ConfirmationState';
import { closeDialog } from './dialogSlice';

export enum ConfirmationActionTypes {
  REQUEST_CONFIRMATION = 'REQUEST_CONFIRMATION',
  CONFIRM = 'CONFIRM',
  // Cancellation is handled by the Dialog itself
}

export const INITIAL_CONFIRMATION_STATE: ConfirmationState = {
  title: undefined,
  description: I18nKeys.ConfirmationDialogDefaultDescription,
  cancelButtonText: I18nKeys.DialogCancelButton,
  buttons: [
    {
      label: I18nKeys.ConfirmationDialogConfirmButton,
      actions: [closeDialog()],
    },
  ],
  keepDialogOpen: false,
  id: 'generic',
};

export const confirmationReducer: Reducer<ConfirmationState> = (
  state = INITIAL_CONFIRMATION_STATE,
  confirmationAction,
) => {
  switch (confirmationAction.type) {
    case ConfirmationActionTypes.REQUEST_CONFIRMATION: {
      const {
        payload: { title, description, cancelButtonText, buttons, keepDialogOpen, id },
      } = confirmationAction;

      return {
        ...state,
        title: title || INITIAL_CONFIRMATION_STATE.title,
        description: description || INITIAL_CONFIRMATION_STATE.description,
        cancelButtonText: cancelButtonText || INITIAL_CONFIRMATION_STATE.cancelButtonText,
        keepDialogOpen: keepDialogOpen || INITIAL_CONFIRMATION_STATE.keepDialogOpen,
        buttons: (buttons || INITIAL_CONFIRMATION_STATE.buttons).map((button: ConfirmationButton) => ({
          ...INITIAL_CONFIRMATION_STATE.buttons[0],
          ...button,
        })),
        id: id || INITIAL_CONFIRMATION_STATE.id,
      };
    }
    default:
      return state;
  }
};

export const openConfirmationDialog = (
  title: string | undefined,
  description: string | undefined,
  cancelButtonText: string | undefined,
  buttons: {
    label?: string;
    actions?: AnyAction[];
    onClick?: () => void;
  }[],
  keepDialogOpen = false,
  id?: string,
): AnyAction =>
  action(ConfirmationActionTypes.REQUEST_CONFIRMATION, {
    title,
    description,
    cancelButtonText,
    buttons,
    keepDialogOpen,
    id,
  });
