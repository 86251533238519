import React, { MutableRefObject, Ref, useEffect, useRef } from 'react';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button } from '@mui/material';
import { useReportRepo } from '../hooks/useReportRepo';
import { dateCellRenderer } from './DateCellRenderer';
import { loadingComponent, noOverlayComponent } from './Reports';
import { useAppDispatch } from '../hooks';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { useRemoveFromSuppressionListMutation } from '../services/sesApi';

const removeFromListCellRenderer = (params: any) => (
  <Button
    variant="contained"
    color="primary"
    onClick={() => {
      params.dispatch(
        openConfirmationDialog(
          'Remove From List',
          `Are you sure you want to remove ${params.data.emailAddress} from the suppression list?`,
          undefined,
          [
            {
              onClick: () => {
                params.removeFromSuppressionList({ emailAddress: params.data.emailAddress });
                params.refetchEmailSuppressionReport();
              },
            },
          ],
        ),
      );
      params.dispatch(openDialog({ dialog: Dialogs.Confirmation }));
    }}
  >
    Remove From List
  </Button>
);

export const EmailSuppressionReport = ({
  gridRef,
  defaultColDef,
}: {
  gridRef?: Ref<AgGridReact | null>;
  defaultColDef: ColDef<any, any>;
}) => {
  const dispatch = useAppDispatch();
  const gridRefInternal = (gridRef as MutableRefObject<AgGridReact | null>) || useRef<AgGridReact | null>(null);
  const { api } = gridRefInternal?.current || {};

  const hideOverlay = false;

  const {
    emailSuppressionReportData,
    isInitializingEmailSuppressionReportData,
    isLoadingEmailSuppressionReportData,
    refetchEmailSuppressionReport,
  } = useReportRepo({
    useEmailSuppressionReport: true,
  });

  const [removeFromSuppressionList] = useRemoveFromSuppressionListMutation();

  useEffect(() => {
    if (api) {
      if (isLoadingEmailSuppressionReportData || isInitializingEmailSuppressionReportData) {
        api.showLoadingOverlay();
      } else if (emailSuppressionReportData.length === 0) {
        api.showNoRowsOverlay();
      } else {
        api.hideOverlay();
        // Updates coming from API need to manually refresh cells
        api.refreshCells();
      }
    }
  }, [emailSuppressionReportData, isInitializingEmailSuppressionReportData, isLoadingEmailSuppressionReportData, api]);

  const columnDefs: ColDef[] = [
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      initialWidth: 350,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    },
    { field: 'reason', headerName: 'Reason', initialWidth: 125 },
    {
      field: 'lastUpdate',
      headerName: 'Last Update Date',
      cellRenderer: dateCellRenderer,
      initialWidth: 215,
    },
    {
      field: 'removeFromSuppressionList',
      headerName: 'Remove From List',
      cellRenderer: removeFromListCellRenderer,
      cellRendererParams: { dispatch, removeFromSuppressionList, refetchEmailSuppressionReport },
      initialWidth: 215,
    },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={emailSuppressionReportData}
        rowHeight={50}
        animateRows
        tooltipShowDelay={0}
        tooltipHideDelay={6000}
        loadingOverlayComponent={hideOverlay ? noOverlayComponent : loadingComponent}
        statusBar={{
          statusPanels: [
            {
              statusPanel: 'agTotalAndFilteredRowCountComponent',
              align: 'left',
            },
          ],
        }}
      />
    </div>
  );
};
