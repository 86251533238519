import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  item: {
    display: 'inline-block',
    verticalAlign: 'middle',
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '14px',
  },
}));

type Props = {
  classes?: Record<string, string>;
  subheader: string;
  iconSrc: string | undefined;
};

export const PricingSheetSubheader: React.FC<Props> = ({ classes: parentClasses = {}, subheader, iconSrc }) => {
  const classes = useStyles();

  if (!subheader) return null;
  return (
    <div className={parentClasses.subheaderContainer}>
      <img alt="Subheader icon" src={iconSrc} className={classes.item} style={{ marginRight: '8px' }} />
      <Typography
        component="div"
        className={`${classes.item} ${parentClasses.subheader}`}
        dangerouslySetInnerHTML={{
          __html: subheader,
        }}
      />
    </div>
  );
};
