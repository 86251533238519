import { AppBar, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { SyntheticEvent, useEffect, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from '../constants/I18nKeys';
import { PricingSurcharge } from './PricingSurcharge';
import { PricingBase } from './PricingBase';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { getClientIdFromClientSupplier, isCarportView } from '../utils/clientIdUtils';
import { setClientId, setSelectedTable } from '../ducks/clientDataSlice';
import { unknownUser } from '../types/User';
import { Loading } from './Loading';
import { AppRoutes } from '../constants/AppRoutes';
import { isIdeaRoomUser } from '../utils/userUtils';
import { PricingTab } from '../constants/Pricing';
import { getPathPart } from '../utils/urlUtils';
import { setSearchHidden } from '../ducks/search';
import { setSelectedPricingTabId } from '../ducks/viewerSlice';
import { PricingClientUpdate } from './PricingClientUpdate';
import { viewingDealer } from '../utils/supplierUtils';
import { unknownGroup } from '../constants/Group';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    minHeight: 0,
    width: '100%',
  },
});

export const Pricing: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { selectedPricingTabId } = useAppSelector((state: AppState) => state?.viewer);

  const clientId = useAppSelector((state: AppState) =>
    getClientIdFromClientSupplier(state?.viewer?.selectedTabId || state?.viewer?.selectedClientId || ''),
  );
  const { clientId: clientDataClientId } = useAppSelector((state: AppState) => state?.clientData);
  const { user } = useAppSelector((state) => state.currentUser || unknownUser);
  const isDealer = useAppSelector(
    ({
      viewer: { selectedTabId, selectedClientId },
      currentUser: { group: { configurators: groupConfigs = [] } = unknownGroup },
    }) => viewingDealer(selectedTabId || selectedClientId, groupConfigs),
  );

  const { pricingEnableClientManaged, pricingEnableClientUpdates, isLoadingPricingEnabled } = usePricingRepo({
    usePricingEnabled: true,
  });

  const tabs = [
    {
      label: t(I18nKeys.Base),
      value: PricingTab.Base,
      route: PricingTab.Base,
      id: 'base-tab',
      enabled: (isIdeaRoomUser(user) || pricingEnableClientManaged) && !isDealer,
    },
    {
      label: t(I18nKeys.SizeBasedTab),
      value: PricingTab.SizeBased,
      route: PricingTab.SizeBased,
      id: 'size-based-tab',
      enabled: (isIdeaRoomUser(user) || pricingEnableClientUpdates) && isCarportView(clientId) && !isDealer,
    },
    {
      label: t(I18nKeys.ComponentTab),
      value: PricingTab.Component,
      route: PricingTab.Component,
      id: 'component-tab',
      enabled: !isDealer,
    },
    {
      label: t(I18nKeys.Adjustments),
      value: PricingTab.Surcharge,
      route: PricingTab.Surcharge,
      id: 'surcharge-tab',
      enabled: true,
    },
  ];

  useLayoutEffect(() => {
    dispatch(setSearchHidden(true));
  }, [dispatch]);

  useEffect(() => {
    if (clientId && clientId !== clientDataClientId) {
      dispatch(setClientId(clientId));
      dispatch(setSelectedPricingTabId(undefined));
    }
  }, [clientId, clientDataClientId, dispatch]);

  useMemo(() => {
    const route = getPathPart(3) as PricingTab;

    if ((!selectedPricingTabId || selectedPricingTabId !== route) && !isLoadingPricingEnabled) {
      if (route === PricingTab.Surcharge) {
        dispatch(setSelectedPricingTabId(PricingTab.Surcharge));
      } else if (
        [PricingTab.Component, PricingTab.Base, PricingTab.SizeBased].includes(route) &&
        tabs.find((pricingTab) => pricingTab.value === route)?.enabled
      ) {
        dispatch(setSelectedPricingTabId(route));
      } else if (route === PricingTab.Base && tabs.find((pricingTab) => pricingTab.value === route)?.enabled) {
        if (isIdeaRoomUser(user) || pricingEnableClientManaged) {
          dispatch(setSelectedPricingTabId(PricingTab.Base));
        } else if (isIdeaRoomUser(user) || pricingEnableClientUpdates) {
          dispatch(setSelectedPricingTabId(PricingTab.Component));
        }
      } else {
        window.history.replaceState(null, '', `${AppRoutes.Pricing}/${PricingTab.Surcharge}`);
        dispatch(setSelectedPricingTabId(PricingTab.Surcharge));
      }
    }
  }, [pricingEnableClientManaged, pricingEnableClientUpdates, selectedPricingTabId, isLoadingPricingEnabled, user]);

  const changeTab = (newTab: PricingTab) => {
    dispatch(setSelectedPricingTabId(newTab));
    dispatch(setSelectedTable(''));
    const route = tabs.find((pricingTab) => pricingTab.value === newTab)?.route;
    window.history.replaceState(null, '', `${AppRoutes.Pricing}/${route}`);
  };

  // Hook that updates when breakpoint value changes to/from xs
  const tabsVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'fullWidth' : undefined;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
        <Tabs
          value={selectedPricingTabId || PricingTab.Surcharge}
          onChange={(event: SyntheticEvent<Element, Event>, newValue: PricingTab): void => {
            changeTab(newValue);
          }}
          aria-label="pricing-tabs"
          variant={tabsVariant}
          indicatorColor="primary"
        >
          {tabs
            .filter((priceTab) => priceTab.enabled)
            .map((appBarTab) => (
              <Tab
                key={appBarTab.id}
                label={appBarTab.label}
                value={appBarTab.value}
                id={appBarTab.id}
                aria-controls={appBarTab.id}
              />
            ))}
        </Tabs>
      </AppBar>

      <div className={classes.content}>
        {!selectedPricingTabId && isLoadingPricingEnabled && <Loading />}
        {selectedPricingTabId === PricingTab.Base && <PricingBase />}
        {selectedPricingTabId &&
          ([PricingTab.Component, PricingTab.SizeBased] as string[]).includes(selectedPricingTabId) && (
            <PricingClientUpdate />
          )}
        {(selectedPricingTabId === PricingTab.Surcharge ||
          (!selectedPricingTabId && !isLoadingPricingEnabled && !pricingEnableClientManaged)) && <PricingSurcharge />}
      </div>
    </div>
  );
};
