import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { AnyAction, Dispatch } from 'redux';
import { KeyboardKeys } from '../constants/ClientData';
import { undoManager } from '../services/undoManager';
import { updatePricingComponentRows } from '../ducks/pricingSlice';
import { ComponentFormData } from '../constants/PricingClientUpdate';
import { PricingComponentEditFields } from '../constants/FormFields';

export const onMuiDataGridKeyboardShortcut = (
  event: KeyboardEvent,
  clientCategoryKey: string,
  focusedCell: { id: string; field: string } | undefined,
  api: GridApiPremium | undefined,
  dispatch: Dispatch<AnyAction>,
): void => {
  const { ctrlKey, metaKey, shiftKey, key } = event;
  const { id: focusedRowId = '', field: focusedColumn = '' } = focusedCell || {};

  // Undo with (ctrl || cmd) + z
  if ((ctrlKey || metaKey) && key === 'z' && !shiftKey) {
    event.preventDefault();
    undoManager.undo(clientCategoryKey);
  }
  // Redo with (ctrl || cmd) + shift + z
  if ((ctrlKey || metaKey) && shiftKey && key === 'z') {
    event.preventDefault();
    undoManager.redo(clientCategoryKey);
  }
  // Move to the edges of the grid with (ctrl || cmd) + arrow
  if (
    ((metaKey || ctrlKey) &&
      ([KeyboardKeys.Up, KeyboardKeys.Down, KeyboardKeys.Left, KeyboardKeys.Right] as string[]).includes(key)) ||
    ([KeyboardKeys.Home, KeyboardKeys.End] as string[]).includes(key)
  ) {
    if (!api || !focusedCell) return;
    event.preventDefault();

    const topRowId = api.getRowIdFromRowIndex(0);
    const bottomRowId = api.getRowIdFromRowIndex(api.getRowsCount() - 1);
    const visibleColumns = api.getVisibleColumns();

    let column = focusedColumn;
    let rowId = focusedRowId;
    switch (key) {
      case KeyboardKeys.Up:
        rowId = `${topRowId}`;
        break;
      case KeyboardKeys.Down:
        rowId = `${bottomRowId}`;
        break;
      case KeyboardKeys.Left:
      case KeyboardKeys.Home:
        column = visibleColumns[0].field;
        break;
      case KeyboardKeys.Right:
      case KeyboardKeys.End:
        column = visibleColumns[visibleColumns.length - 1].field;
        break;
      default:
        break;
    }
    api.setCellFocus(rowId, column);
    api.scrollToIndexes({
      rowIndex: api.getRowIndexRelativeToVisibleRows(rowId),
      colIndex: api.getColumnIndex(column),
    });
  }
  // Move to the next cell to the right with (ctrl || cmd) + tab
  if (key === KeyboardKeys.Tab) {
    if (!api || !focusedCell) return;
    event.preventDefault();

    const visibleColumns = api.getVisibleColumns();
    const newColumnIndex = shiftKey
      ? visibleColumns.findIndex(({ field }) => field === focusedColumn) - 1
      : visibleColumns.findIndex(({ field }) => field === focusedColumn) + 1;

    if (newColumnIndex >= visibleColumns.length || newColumnIndex < 0) return;

    const nextColumn = visibleColumns[newColumnIndex].field;
    api.setCellFocus(focusedRowId, nextColumn);
    api.scrollToIndexes({
      rowIndex: api.getRowIndexRelativeToVisibleRows(focusedRowId),
      colIndex: api.getColumnIndex(nextColumn),
    });
  }
  if ((metaKey || ctrlKey) && key === 'v') {
    if (!api || !focusedCell) return;
    event.preventDefault();
    event.stopPropagation();

    const focusedRowIndex = api.getRowIndexRelativeToVisibleRows(focusedRowId);
    const focusedColumnIndex = api.getColumnIndex(focusedColumn);
    const visibleColumns = api.getVisibleColumns();
    const rowCount = api.getRowsCount();
    const { pagination: { paginationModel: { page = 0, pageSize = rowCount } = {} } = {} } = api.exportState();

    navigator.clipboard.readText().then((text) => {
      const rowUpdates = text.split('\n').reduce((updates, row, rowIndex) => {
        const newRowIndex = focusedRowIndex + rowIndex;
        // Don't exceed the overall number of rows
        if (page * pageSize + newRowIndex >= rowCount) return updates;

        const rowId = api.getRowIdFromRowIndex(focusedRowIndex + rowIndex);

        const rowUpdate = row.split('\t').reduce(
          (update, val, columnIndex) => {
            const newColumnIndex = focusedColumnIndex + columnIndex;
            if (newColumnIndex >= visibleColumns.length) return update;

            const { field } = visibleColumns[newColumnIndex];
            return {
              ...update,
              [field]: val,
            };
          },
          { [PricingComponentEditFields.Component]: rowId } as ComponentFormData,
        );
        return [...updates, rowUpdate];
      }, [] as ComponentFormData[]);
      dispatch(updatePricingComponentRows(rowUpdates));
    });
  }
};
