import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';
import { OrderResponse } from '../types/OrderResponse';
import { OrderRequest } from '../types/OrderRequest';
import { PaymentOrder } from '../types/PaymentOrder';

export const leadApi = createApi({
  reducerPath: 'leadApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/orders',
  }),
  endpoints: (builder) => ({
    /**
     * Gets a list of all leads
     */
    getLeads: builder.query<
      {
        leads: OrderResponse;
      },
      {
        leadRequest: OrderRequest;
      }
    >({
      query: ({ leadRequest }) => ({
        url: `/`,
        method: 'get',
        init: leadRequest.options,
      }),
    }),

    /**
     * Gets a list of leads as a CSV
     */
    getLeadsCSV: builder.query<
      {
        csv: Blob;
      },
      {
        leadRequest: OrderRequest;
      }
    >({
      query: ({ leadRequest }) => ({
        url: `/csv`,
        method: 'get',
        init: {
          ...leadRequest.options,
          responseType: 'blob',
        },
      }),
    }),

    getPayments: builder.query<
      PaymentOrder[],
      {
        paymentRequest: OrderRequest;
      }
    >({
      query: ({ paymentRequest }) => ({
        url: `/payments`,
        method: 'get',
        init: paymentRequest.options,
      }),
    }),

    /**
     * Sets or updates a value for a JSON key in portalInfo. Providing 'null' or undefined is the same as
     * removing the value from the JSON document.
     */
    updatePortalInfo: builder.mutation<
      void,
      {
        clientId: string;
        uuid: string;
        key: string;
        value?: any;
      }
    >({
      query: ({ clientId, uuid, key, value }) => ({
        url: `/portal-info`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId }),
          body: {
            uuid,
            portalInfo: {
              [key]: value,
            },
          },
        },
      }),
    }),

    /**
     * Assigns a lead to a sales rep
     */
    assignLead: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        uuid: string;
        emailAddress: string;
        status: string;
        site: string;
        logoUrl: string;
        buttonColor: string;
      }
    >({
      query: ({ groupId, clientId, uuid, emailAddress, status, site, logoUrl, buttonColor }) => ({
        url: `/assign`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: {
            uuid,
            emailAddress,
            status,
            site,
            logoUrl,
            buttonColor,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useGetPaymentsQuery,
  useGetLeadsCSVQuery,
  useUpdatePortalInfoMutation,
  useAssignLeadMutation,
} = leadApi;
