export enum UserStatus {
  Unconfirmed = 'UNCONFIRMED',
  Confirmed = 'CONFIRMED',
  Archived = 'ARCHIVED',
  Compromised = 'COMPROMISED',
  Unknown = 'UNKNOWN',
  ResetRequired = 'RESET_REQUIRED',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
}

export enum UserPreference {
  ClientDataPreferences = 'clientData',
  PricingBasePreferences = 'pricingBase',
  PricingComponentPreferences = 'pricingComponent',
  SitesPreferences = 'sites',
  ProfilePreferences = 'profile',
  MUIDataGrid = 'muiDataGrid',
}

export enum UserCacheTagType {
  CurrentUserData = 'CurrentUserData',
  CurrentUserGroups = 'CurrentUserGroups',
  CurrentUserConfigurators = 'CurrentUserConfigurators',
  CurrentUserPreferences = 'CurrentUserPreferences',
  ValidUser = 'ValidUser',
}
