import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks';
import { PriceByQuantity } from '../constants/PricingClientUpdate';
import { PricingSheet } from '../types/PricingSheet';
import { SizeBasedCategoryKey } from '../constants/ClientUpdateCategoryKey';
import { setPriceByQuantity, updatePricingSheetRows } from '../ducks/pricingSlice';
import { getPricingSheetTable, getUpdatesForPriceByQuantityChange } from '../utils/pricingSheetUtils';
import { PricingSheetRegion } from '../types/Region';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { openConfirmationDialog } from '../ducks/confirmation';
import { I18nKeys } from '../constants/I18nKeys';
import { splitViewIsEnabled } from '../utils/pricingClientUpdateUtils';

const useStyles = makeStyles(() => ({
  radioGroupContainer: {
    margin: '0px 0px 10px',
    padding: '18px 5px',
    borderBottom: `1.5px solid #DADADA`,
  },
}));

export const PriceByQuantityRadioGroup: React.FC<{
  selectedPricingSheet: PricingSheet | undefined;
  regions: PricingSheetRegion[];
}> = ({ selectedPricingSheet, regions }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    pricing: {
      sizeBased: { priceByQuantity = PriceByQuantity.Each },
    },
    viewer: { selectedPricingTabId: pricingTab },
    clientData: { clientId },
  } = useAppSelector((state) => state);

  if (
    !selectedPricingSheet ||
    !(
      [SizeBasedCategoryKey.EndWalls, SizeBasedCategoryKey.SideWalls, SizeBasedCategoryKey.Siding] as string[]
    ).includes(selectedPricingSheet.category || '')
  )
    return null;
  return (
    <div className={classes.radioGroupContainer}>
      <FormControl>
        <RadioGroup
          aria-label="price by quantity"
          value={priceByQuantity}
          onChange={({ target: { value } }): void => {
            const switchQuantity = (calculateValues: boolean): void => {
              const updates = getUpdatesForPriceByQuantityChange(
                getPricingSheetTable(clientId, pricingTab, selectedPricingSheet.category),
                priceByQuantity,
                value as PriceByQuantity,
                selectedPricingSheet,
                regions,
                calculateValues,
              );
              dispatch(updatePricingSheetRows(updates));
              dispatch(setPriceByQuantity(value as PriceByQuantity));
            };

            // No confirmation for moving to split view
            if (value === PriceByQuantity.Split) {
              switchQuantity(false);
              return;
            }
            if (priceByQuantity === PriceByQuantity.Split) {
              dispatch(
                openConfirmationDialog(
                  undefined,
                  value === PriceByQuantity.Each
                    ? t(I18nKeys.PricingPriceByQuantitySplitToEachConfirmation)
                    : t(I18nKeys.PricingPriceByQuantitySplitToCombinedConfirmation),
                  undefined,
                  [
                    {
                      label: t(I18nKeys.ContinueButton),
                      onClick: () => switchQuantity(false),
                    },
                  ],
                ),
              );
            } else {
              dispatch(
                openConfirmationDialog(
                  undefined,
                  value === PriceByQuantity.Each
                    ? t(I18nKeys.PricingPriceByQuantityCombinedToEachConfirmation)
                    : t(I18nKeys.PricingPriceByQuantityEachToCombinedConfirmation),
                  undefined,
                  [
                    {
                      label: t(I18nKeys.LeaveAsIsButton),
                      onClick: () => switchQuantity(false),
                    },
                    {
                      label: t(value === PriceByQuantity.Each ? I18nKeys.HalfButton : I18nKeys.DoubleButton),
                      onClick: () => switchQuantity(true),
                    },
                  ],
                ),
              );
            }
            dispatch(openDialog({ dialog: Dialogs.Confirmation }));
          }}
          name="price-by-quantity"
        >
          {[
            PriceByQuantity.Each,
            PriceByQuantity.Combined,
            ...(splitViewIsEnabled(selectedPricingSheet) ? [PriceByQuantity.Split] : []),
          ].map((option) => (
            <FormControlLabel
              value={option}
              control={<Radio color="primary" />}
              label={t(`pricing-price-by-quantity-${option}`)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
