import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Grid2 as Grid, Tabs, Tab, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { Integration, IntegrationType, IntegrationStatus } from '@idearoom/types';
import { useTranslation } from 'react-i18next';
import { useIntegrationRepo } from '../hooks/useIntegrationRepo';
import { setSearchHidden } from '../ducks/search';
import { Loading } from './Loading';
import { IntegrationsHeader } from './IntegrationsHeader';
import { IntegrationsCard } from './IntegrationsCard';
import { IntegrationsDialog } from './IntegrationsDialog';
import { IntegrationsDeactivateDialog } from './IntegrationsDeactivateDialog';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles(() => ({
  installedTitle: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  leftPadding: {
    paddingLeft: '40px',
  },
  tabs: {
    position: 'sticky',
    top: 0,
    background: 'white',
    margin: '12px 0px',
    zIndex: 1,
  },
}));

enum AllTab {
  All = 'all',
}
type TabName = AllTab | IntegrationType;

type Tab =
  | {
      [tabName in TabName]?: Integration[];
    }
  | undefined;

export const Integrations: React.FC = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { integrations, isLoadingIntegrations } = useIntegrationRepo({ useIntegrations: true });
  const [availableIntegrations, setAvailableIntegrations] = useState<Tab>();
  const [cardHeight, setCardHeight] = useState<number | undefined>(undefined);
  const [currentTab, setCurrentTab] = React.useState<TabName>(AllTab.All);
  const [tabKeys, setTabKeys] = React.useState<string[]>([]);
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const newTabs: Tab = {};
    let includesOther = false;
    for (let index = 0; index < integrations.length; index += 1) {
      const integration = integrations[index];
      if (![IntegrationStatus.Connected, IntegrationStatus.Deactivated].includes(integration.status)) {
        const { [AllTab.All]: allTab = [], [integration.type]: typeTab = [] } = newTabs;
        allTab.push(integration);
        typeTab.push(integration);
        newTabs[AllTab.All] = allTab;
        newTabs[integration.type] = typeTab;
        includesOther = includesOther || integration.type === IntegrationType.Other;
      }
    }

    const sorted = Object.keys(newTabs)
      .filter((tab) => tab !== IntegrationType.Other)
      .sort();

    if (includesOther) {
      sorted.push(IntegrationType.Other);
    }

    setTabKeys(sorted);
    setAvailableIntegrations(newTabs);
  }, [integrations, setAvailableIntegrations]);

  useLayoutEffect(() => {
    dispatch(setSearchHidden(true));
  }, [dispatch]);

  useEffect(() => {
    if (tabKeys.length > 0 && rootRef && rootRef.current && rootRef.current.children) {
      let computedCardHeight = 0;
      for (let index = 0; index < rootRef.current.children.length; index += 1) {
        const { children } = rootRef.current.children[index];
        if (children.length > 0) {
          const firstChild = children.item(0);
          if (firstChild && firstChild.clientHeight > computedCardHeight) {
            computedCardHeight = firstChild.clientHeight;
          }
        }
      }

      if (!cardHeight || computedCardHeight > cardHeight) {
        setCardHeight(computedCardHeight);
      }
    }
  }, [tabKeys, rootRef, setCardHeight, cardHeight]);

  return (
    <Stack direction="column" overflow="auto" width="100%" paddingBlockEnd="29px">
      <IntegrationsHeader />
      {!availableIntegrations || (tabKeys.length === 0 && isLoadingIntegrations) ? (
        <Loading />
      ) : (
        <>
          <Typography className={`${classes.installedTitle} ${classes.leftPadding}`}>
            {t(I18nKeys.IntegrationsAvailable)}
          </Typography>
          <Tabs
            allowScrollButtonsMobile
            className={classes.tabs}
            value={currentTab}
            aria-label="integration-tabs"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: TabName): void => {
              setCurrentTab(newValue);
            }}
          >
            {tabKeys.map((key) => (
              <Tab
                key={key}
                label={
                  key === AllTab.All
                    ? t(I18nKeys.IntegrationsTabTitleAll)
                    : t(I18nKeys.IntegrationsType, { context: key })
                }
                value={key}
              />
            ))}
          </Tabs>
          <Grid container paddingInline={5} spacing={3} ref={rootRef}>
            {(availableIntegrations[currentTab] || []).map((integration) => (
              <IntegrationsCard key={integration.name} integration={integration} cardHeight={cardHeight} />
            ))}
          </Grid>
        </>
      )}

      <IntegrationsDialog />
      <IntegrationsDeactivateDialog />
    </Stack>
  );
};
