/* eslint-disable no-param-reassign */
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { Integration } from '@idearoom/types';
import { IntegrationState } from '../types/IntegrationState';

const initialState: IntegrationState = {
  updatingIntegration: false,
};

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    isUpdatingIntegration(state, action: PayloadAction<boolean>) {
      state.updatingIntegration = action.payload;
    },
  },
});

export const { isUpdatingIntegration } = integrationSlice.actions;

export const startPaymentIntegration = createAction(
  'integration/startPaymentIntegration',
  (payload?: { legalEntityName: string; shopperStatement: string }) => ({
    payload,
  }),
);
export const putIntegration = createAction(
  'integration/putIntegration',
  (payload: { integration: Integration; enabled?: boolean }) => ({
    payload,
  }),
);
