import React, { useEffect, useState } from 'react';
import { Grid2 as Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Integration, IntegrationStatus } from '@idearoom/types';
import { useTranslation } from 'react-i18next';
import menuIntegrationsIcon from '../images/menuIntegrationsIcon.svg';
import { useIntegrationRepo } from '../hooks/useIntegrationRepo';
import { IntegrationsCard } from './IntegrationsCard';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles(() => ({
  integrationIcon: { marginLeft: '25px', marginTop: '20px', width: '48px', height: '48px' },
  integrationIconCircle: {
    height: 0,
    width: 0,
    minWidth: '91px',
    minHeight: '91px',
    background: '#0000001A',
    borderRadius: '50%',
    display: 'flex',
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '23.44px',
    color: '#000',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '22px',
    color: '#1E1E1E',
  },
  installedTitle: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  oneLineText: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
}));

export const IntegrationsHeader: React.FC = () => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { integrations } = useIntegrationRepo({ useIntegrations: true });
  const [connectedIntegrations, setConnectedIntegrations] = useState<Integration[]>();

  useEffect(() => {
    const result: Integration[] = [];
    for (let index = 0; index < integrations.length; index += 1) {
      const integration = integrations[index];
      if ([IntegrationStatus.Connected, IntegrationStatus.Deactivated].includes(integration.status)) {
        result.push(integration);
      }
    }

    setConnectedIntegrations(result);
  }, [integrations, setConnectedIntegrations]);

  return (
    <Grid container>
      <Grid container paddingBlockStart={3.75} size={12} direction="column" paddingInline={5}>
        <Grid container marginBlockEnd={6}>
          <Grid>
            <div className={classes.integrationIconCircle}>
              <img alt="Integration Icon" src={menuIntegrationsIcon} className={classes.integrationIcon} />
            </div>
          </Grid>
          <Grid container size={5} alignItems="center" paddingInlineStart={3.25}>
            <Stack spacing={1}>
              <Typography className={`${classes.title} ${classes.oneLineText}`}>
                {t(I18nKeys.IntegrationsPageTitle)}
              </Typography>
              <Typography className={`${classes.subtitle}`}>{t(I18nKeys.IntegrationsPageSubtitle)}</Typography>
            </Stack>
          </Grid>
        </Grid>
        {connectedIntegrations && connectedIntegrations.length > 0 && (
          <>
            <Typography className={classes.installedTitle}>{t(I18nKeys.IntegrationsInstalled)}</Typography>
            <Grid container spacing={3} paddingBlockEnd={4} paddingBlockStart={2}>
              {connectedIntegrations.map((integration) => (
                <IntegrationsCard key={integration.type} integration={integration} />
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
