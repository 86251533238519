import React from 'react';
import { PriceCalculation, PriceColumn } from '@idearoom/types';
import { Field, Form, InjectedFormProps, reduxForm, formValueSelector, change } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Theme, Grid2 as Grid, MenuItem, FormControl, FormHelperText, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Forms } from '../constants/Forms';
import { PricingComponentEditFields } from '../constants/FormFields';
import { CheckboxField } from './redux-form/CheckboxField';
import { InputField } from './redux-form/InputField';
import { PriceField } from './redux-form/PriceField';
import { RenderSelectField } from './RenderSelectField';
import { AppState } from '../types/AppState';
import { I18nKeys } from '../constants/I18nKeys';
import {
  ActionColumn,
  CLIENT_UPDATE_CATEGORY_MAPPING,
  ComponentAttributeColumns,
  DisplayColumns,
  ComponentFormData,
  HelperColumns,
  MiscPriceColumns,
  PricingCalculationColumns,
  componentColumnMap,
} from '../constants/PricingClientUpdate';
import {
  getComponentFieldLabel,
  getPricingTypeLabel,
  isPricingField,
  isRegionalPricingField,
  displayVaryByRegion,
  onComponentFieldChange,
  getComponentAttributeLabel,
  contactSupportForPricing,
} from '../utils/pricingClientUpdateUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { useClientUpdatePricingRepo } from '../hooks/useClientUpdatePricingRepo';

const useStyles = makeStyles((theme: Theme) => ({
  fieldContainer: {
    marginTop: theme.spacing(2),
  },
  field: {
    margin: '0px',
    padding: '0px',
  },
  helperText: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    letterSpacing: 'inherit',
    lineHeight: 'inherit',
  },
  checkboxContainer: {
    margin: '8px 0px',
    cursor: 'pointer',
    width: 'fit-content',
  },
  checkboxField: {
    marginRight: '8px',
  },
}));

export interface FormData {
  [PricingComponentEditFields.Component]: string;
  [PricingComponentEditFields.Table]: string;
  [DisplayColumns.Label]: string;
  [PriceColumn.price]?: string;
  [PriceColumn.region1]?: string;
  [PriceColumn.region2]?: string;
  [PriceColumn.region3]?: string;
  [PriceColumn.region4]?: string;
  [PriceColumn.region5]?: string;
  [PriceColumn.region6]?: string;
  [PriceColumn.region7]?: string;
  [PricingCalculationColumns.PriceCalculation]?: string;
  [MiscPriceColumns.UpgradePrice]?: string;
  [DisplayColumns.PriceExpression]?: string;
  [DisplayColumns.StyleKey]?: string;
  [ComponentAttributeColumns.WallPosition]?: string;
  [ComponentAttributeColumns.WidthTags]?: string;
  [ComponentAttributeColumns.Height]?: string;
  [ComponentAttributeColumns.Width]?: string;
  [ComponentAttributeColumns.CustomExpression]?: string;
  [HelperColumns.VariesByRegion]: boolean;
  [HelperColumns.Key]: string;
}

interface CustomProps {
  formFields: (
    | PricingComponentEditFields
    | DisplayColumns
    | ComponentAttributeColumns
    | HelperColumns.Key
    | HelperColumns.VariesByRegion
    | ActionColumn
    | PriceColumn
    | MiscPriceColumns
    | PricingCalculationColumns
  )[];
  showKeyAsHelperText?: boolean;
  onSubmit: { (values: ComponentFormData): void };
}

type FormProps = CustomProps & InjectedFormProps<ComponentFormData, CustomProps>;

const PricingComponentEditFormComponent: React.FC<FormProps> = ({
  handleSubmit,
  formFields,
  showKeyAsHelperText = false,
  onSubmit,
}: FormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    component: { selectedCategoryKey: componentCategoryKey = '' },
  } = useAppSelector((state: AppState) => state?.pricing);

  const currentValues = useAppSelector((state) => formValueSelector(Forms.PricingComponentEdit)(state, ...formFields));

  const {
    clientTableColumns: { [currentValues[PricingComponentEditFields.Table]]: componentTableColumns = [] },
    isLoadingClientTableColumns,
  } = useClientDataRepo({
    useClientTablesColumns: true,
  });

  const {
    componentCategoryItemsWithConditions = [],
    clientUpdateRegions: regions = [],
    clientUpdateStyles: styles = [],
  } = useClientUpdatePricingRepo({
    useComponentCategoryItemsWithConditions: true,
    useClientUpdateRegions: true,
    useClientUpdateStyles: true,
  });
  const selectedItemWithConditions = componentCategoryItemsWithConditions.find(
    ({ item }) => item.rowId === currentValues[PricingComponentEditFields.Component],
  );

  if (!componentCategoryKey || !selectedItemWithConditions) return null;

  const enableRegions = displayVaryByRegion(regions, componentTableColumns);
  const expressionExists = formFields.includes(DisplayColumns.PriceExpression);
  const varyPriceByRegion = enableRegions && currentValues[HelperColumns.VariesByRegion];

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowGap={2} columnGap={2} className={classes.fieldContainer}>
        {formFields.map((field) => {
          if (
            (
              [[DisplayColumns.Label, DisplayColumns.PriceExpression], PriceColumn, MiscPriceColumns].flatMap((e) =>
                Object.values(e),
              ) as string[]
            ).includes(field)
          ) {
            const isLabelField = DisplayColumns.Label === field;
            const isExpressionField = DisplayColumns.PriceExpression === field;
            const isPriceField = isPricingField(field);
            const displayPricingCalculation = !!componentColumnMap[field as string]?.calculation;
            const allowEditing = !contactSupportForPricing(field as string, selectedItemWithConditions);

            const attributes = [
              DisplayColumns.StyleKey as DisplayColumns.StyleKey,
              ...Object.values(ComponentAttributeColumns),
            ].filter(
              (c) =>
                selectedItemWithConditions.item[c] &&
                !contactSupportForPricing(PriceColumn.price, selectedItemWithConditions),
            );

            // Only display region prices that exist for the table and are used by the vendor's regions
            if (
              isRegionalPricingField(field) &&
              (!varyPriceByRegion ||
                !enableRegions ||
                regions.every(({ priceColumn }) => priceColumn !== (field as string)))
            ) {
              return null;
            }
            return (
              <Grid
                container
                direction="row"
                key={field}
                spacing={2}
                size={{ xs: isPriceField && !displayPricingCalculation ? 6 : 12 }}
              >
                {displayPricingCalculation && (
                  <Grid size={{ xs: 6 }}>
                    <FormControl fullWidth>
                      <Field
                        component={RenderSelectField}
                        label={t(I18nKeys.PricingCalculationField)}
                        name={componentColumnMap[field as string]?.calculation}
                        variant="filled"
                        className={classes.field}
                        disabled
                        SelectProps={{
                          renderValue: (value: PriceCalculation) => getPricingTypeLabel(value),
                        }}
                      >
                        {Object.entries(PriceCalculation).map(([, value]) => (
                          <MenuItem key={value} value={value} />
                        ))}
                      </Field>
                      {!isLoadingClientTableColumns &&
                        !componentTableColumns.includes(PricingCalculationColumns.PriceCalculation) && (
                          <FormHelperText>
                            <Typography
                              component="span"
                              className={classes.helperText}
                              dangerouslySetInnerHTML={{
                                __html: t(I18nKeys.PricingComponentNoPricingType, {
                                  category: t(CLIENT_UPDATE_CATEGORY_MAPPING[componentCategoryKey]?.label),
                                }),
                              }}
                            />
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                )}
                <Grid size={{ xs: displayPricingCalculation ? 6 : 12 }}>
                  <FormControl fullWidth>
                    {isPriceField ? (
                      <Field
                        component={PriceField}
                        name={field}
                        autoComplete="off"
                        label={getComponentFieldLabel(field, regions, varyPriceByRegion)}
                        variant="filled"
                        className={classes.field}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          onComponentFieldChange(event, currentValues as ComponentFormData, dispatch)
                        }
                        disabled={(isPriceField && expressionExists) || !allowEditing}
                        hideHelperText
                        errorWithoutTouch={false}
                      />
                    ) : (
                      <Field
                        name={field}
                        autoComplete="off"
                        component={InputField}
                        label={getComponentFieldLabel(field, regions, varyPriceByRegion)}
                        variant="filled"
                        className={classes.field}
                        slotProps={{
                          input: {
                            multiline: isExpressionField,
                            minRows: 5,
                            maxRows: 5,
                            style: {
                              ...(isExpressionField ? { padding: '12px 8px', fontFamily: 'monaco' } : {}),
                            },
                          },
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                          onComponentFieldChange(event, currentValues as FormData, dispatch)
                        }
                        disabled={isExpressionField || (isPriceField && expressionExists) || !allowEditing}
                      />
                    )}
                    {isExpressionField && (
                      <FormHelperText>
                        <Typography
                          component="span"
                          className={classes.helperText}
                          dangerouslySetInnerHTML={{
                            __html: t(I18nKeys.PricingComponentPriceExpressionHelp),
                          }}
                        />
                      </FormHelperText>
                    )}
                    {showKeyAsHelperText && isLabelField && (
                      <FormHelperText>
                        <Typography component="span" className={classes.helperText}>
                          {`${t(I18nKeys.PricingComponentKeyLabel)} ${currentValues[HelperColumns.Key]}`}
                        </Typography>
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {isLabelField &&
                  !!attributes.length &&
                  attributes.map((attribute) => (
                    <Grid size={{ xs: 12 }} key={attribute}>
                      <Typography>
                        {getComponentAttributeLabel(
                          attribute,
                          currentValues[PricingComponentEditFields.Component],
                          componentCategoryKey,
                          componentCategoryItemsWithConditions,
                          styles,
                          false,
                        )}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            );
          }

          if (enableRegions && HelperColumns.VariesByRegion === field) {
            return (
              <Grid size={{ xs: 12 }}>
                <Box
                  className={classes.checkboxContainer}
                  onClick={(e): void => {
                    e.stopPropagation();
                    dispatch(change(Forms.PricingComponentEdit, field, !currentValues[field]));
                  }}
                >
                  <Field
                    name={field}
                    autoComplete="off"
                    component={CheckboxField}
                    variant="filled"
                    className={`${classes.field} ${classes.checkboxField}`}
                  />
                  <Typography component="span" style={{ verticalAlign: 'middle' }}>
                    {t(I18nKeys.PricingVaryByRegion)}
                  </Typography>
                </Box>
              </Grid>
            );
          }

          return null;
        })}
      </Grid>
    </Form>
  );
};

export const PricingComponentEditForm = reduxForm<ComponentFormData, CustomProps>({
  form: Forms.PricingComponentEdit,
})(PricingComponentEditFormComponent);
