import React from 'react';
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { SetupPaymentIntegrationOptions } from '../types/DialogState';
import { startPaymentIntegration } from '../ducks/integrationSlice';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '22px',
    color: '#000000DE',
  },
  fieldsList: {
    margin: '0px',
    padding: '8px 24px',
  },
  bold: {
    fontWeight: '700',
  },
}));

export const SetupPaymentIntegrationConfirmationDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { options = {} } = useAppSelector((state) => state.dialog || {});
  const { legalEntityName, shopperStatement } = options as SetupPaymentIntegrationOptions;
  const openPreviousDialog = () => {
    dispatch(openDialog({ dialog: Dialogs.SetupPaymentIntegration, options: { legalEntityName, shopperStatement } }));
  };

  const onContinue = () => {
    dispatch(startPaymentIntegration({ legalEntityName, shopperStatement }));
    dispatch(
      openDialog({
        dialog: Dialogs.Loading,
        options: {
          title: t(I18nKeys.SetupPaymentIntegrationLoadingTitle),
          content: t(I18nKeys.SetupPaymentIntegrationLoadingContent),
        },
      }),
    );
  };

  return (
    <Dialog dialogKey={Dialogs.SetupPaymentIntegrationConfirmation} onClosed={openPreviousDialog}>
      <DialogContent>
        <Typography className={classes.text}>{t(I18nKeys.SetupPaymentIntegrationConfirmationDialogTitle)}</Typography>
        <ul className={classes.fieldsList}>
          <li>
            <Typography className={classes.text} display="inline">{`${t(
              I18nKeys.SetupPaymentIntegrationDialogLegalName,
            )}: `}</Typography>
            <Typography className={`${classes.text} ${classes.bold}`} display="inline">
              {legalEntityName}
            </Typography>
          </li>
          <li>
            <Typography className={classes.text} display="inline">
              {`${t(I18nKeys.SetupPaymentIntegrationDialogShopperStatement)}: `}
            </Typography>
            <Typography className={`${classes.text} ${classes.bold}`} display="inline">
              {shopperStatement}
            </Typography>
          </li>
        </ul>
        <Typography className={classes.text}>
          {t(I18nKeys.SetupPaymentIntegrationConfirmationDialogDisclaimer)}
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: '0px 8px 8px 8px' }}>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
            openPreviousDialog();
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button color="primary" onClick={onContinue}>
          {t(I18nKeys.DialogContinueButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
