import React from 'react';
import { FormControlLabel, FormControl, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PricingSurchargeCalculationType, PricingSurchargeSupplierOption } from '@idearoom/types';
import { AppState } from '../types/AppState';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { openConfirmationDialog } from '../ducks/confirmation';
import { unknownGroup } from '../constants/Group';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { useAppDispatch, useAppSelector } from '../hooks';
import { SurchargeUpdateProperty } from '../constants/PricingSurcharge';
import { updateSurchargeProperty } from '../ducks/pricingSlice';
import { usePricingRepo } from '../hooks/usePricingRepo';

export const PricingSurchargeSupplierSelector: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    clientData: { clientId },
    currentUser: { group: { configurators } = unknownGroup },
    pricing: {
      surcharge: { supplierKey = '' },
    },
  } = useAppSelector((state: AppState) => state);

  const { selectedPricingSurcharge } = usePricingRepo({
    useBranches: true,
    useSelectedPricingSurcharge: true,
  });
  const { supplierSurcharge = PricingSurchargeSupplierOption.Inherit } = selectedPricingSurcharge;

  const configurator = configurators ? configurators.find((config) => config.clientId === clientId) : undefined;
  const supplier =
    configurator && configurator.suppliers && configurator.suppliers.length > 0
      ? configurator.suppliers.find((supp) => supp.supplierKey === supplierKey)
      : undefined;
  const supplierName = supplier ? supplier.supplierName || supplierKey : supplierKey;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selection = (event.target as HTMLInputElement).value;

    if (selection === PricingSurchargeSupplierOption.Off) {
      dispatch(
        updateSurchargeProperty([
          { property: SurchargeUpdateProperty.Type, value: PricingSurchargeCalculationType.None },
          { property: SurchargeUpdateProperty.SupplierSurchargeOption, value: PricingSurchargeSupplierOption.Inherit },
        ]),
      );
    } else {
      dispatch(
        openConfirmationDialog(
          i18n.t(I18nKeys.PricingSupplierSelectorConfirmationTitle, {
            supplierName,
            surchargeDiscount: 'price adjustment',
          }),
          undefined,
          i18n.t(I18nKeys.PricingSupplierSelectorConfirmationTurnOff),
          [
            {
              actions: [
                updateSurchargeProperty([
                  { property: SurchargeUpdateProperty.Type, value: PricingSurchargeCalculationType.None },
                  {
                    property: SurchargeUpdateProperty.SupplierSurchargeOption,
                    value: PricingSurchargeSupplierOption.Off,
                  },
                ]),
              ],
            },
          ],
        ),
      );
      dispatch(openDialog({ dialog: Dialogs.Confirmation }));
    }
  };

  return (
    <FormControl component="fieldset">
      <FormControlLabel
        control={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Checkbox
            checked={supplierSurcharge === PricingSurchargeSupplierOption.Inherit}
            name="pricingOptions"
            value={supplierSurcharge}
            onChange={handleChange}
            color="primary"
          />
        }
        key={supplierSurcharge}
        label={t(I18nKeys.PricingSupplierSelector, {
          supplierName,
          surchargeDiscount: 'price adjustment',
        })}
      />
    </FormControl>
  );
};
