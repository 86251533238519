import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    diffAdd: Palette['primary'];
    diffRemove: Palette['primary'];
  }
  interface PaletteOptions {
    diffAdd: PaletteOptions['primary'];
    diffRemove: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    buttonGray: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    buttonGray: true;
  }
}

const { palette } = createTheme();
export const theme = (
  selectedTextColor = '#F36F3A',
  contactBarColor = '#FFFFFF',
  contactBarTextColor = '#FFFFFF',
): any => ({
  palette: {
    primary: {
      main: selectedTextColor,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: contactBarColor,
      contrastText: contactBarTextColor,
    },
    buttonGray: palette.augmentColor({
      color: {
        main: '#000000DE',
      },
    }),
    diffAdd: {
      main: 'rgba(56, 118, 30, 0.20)',
    },
    diffRemove: {
      main: 'rgba(176, 0, 32, 0.20)',
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          background: '$labelcolor',
        },
      },
    },
    MuiCollapse: {
      hidden: {
        display: 'none',
      },
    },
  },
});

// this could be pushed into the mui text: disabled but would need a little refactoring
export const disabled = 0.4;
