/* eslint-disable react-hooks/rules-of-hooks */
import { Integration } from '@idearoom/types';
import { OnlyExistingKeys } from '../types/Repo';
import { useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { useGetIntegrationsQuery } from '../services/integrationApi';
import { unknownGroup } from '../constants/Group';

const EMPTY_INTEGRATIONS_REFERENCE: Readonly<Integration[]> = [];

type UseIntegrationRepoReturn<UseIntegrationsType> = OnlyExistingKeys<{
  integrations: UseIntegrationsType extends true ? Integration[] : never;
  isLoadingIntegrations: UseIntegrationsType extends true ? boolean : never;
}>;

export const useIntegrationRepo = <UseIntegrationsType extends boolean = false>({
  useIntegrations,
}: {
  useIntegrations?: UseIntegrationsType;
} = {}): UseIntegrationRepoReturn<UseIntegrationsType> => {
  const clientId = useAppSelector(
    ({ viewer: { selectedClientId, selectedTabId }, clientData: { clientId: clientDataClientId } }: AppState) =>
      getClientIdFromClientSupplier(clientDataClientId || selectedTabId || selectedClientId || ''),
  );
  const { group: { groupId } = unknownGroup } = useAppSelector((state: AppState) => state?.currentUser);
  const result: any = {};

  if (useIntegrations) {
    const { data: integrations = EMPTY_INTEGRATIONS_REFERENCE, isFetching: isLoadingIntegrations } =
      useGetIntegrationsQuery(
        { clientId, groupId },
        {
          skip: !clientId,
          refetchOnMountOrArgChange: true,
        },
      );
    result.integrations = integrations;
    result.isLoadingIntegrations = isLoadingIntegrations;
  }

  return result;
};
