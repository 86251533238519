import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removeSceneEnvironment } from '../ducks/settings';
import { I18nKeys } from '../constants/I18nKeys';
import { SceneEnvironment } from '../types/SceneEnvironment';
import { openConfirmationDialog } from '../ducks/confirmation';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';

interface Props {
  sceneEnvironment: SceneEnvironment;
}

export const SceneEnvironmentMenu: React.FC<Props> = ({ sceneEnvironment }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation(); // Prevent table row event from being called
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation(); // Prevent table row event from being called
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        style={{ padding: '6px' }}
        aria-controls="group-scene-environment-group-menu-btn"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="scene-environment-group-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListItemText
            onClick={(event: React.MouseEvent<HTMLElement>): void => {
              dispatch(
                openConfirmationDialog(
                  undefined,
                  t(I18nKeys.SceneEnvironmentConfirmationDialogDescription),
                  undefined,
                  [{ actions: [removeSceneEnvironment(sceneEnvironment)] }],
                ),
              );
              dispatch(openDialog({ dialog: Dialogs.Confirmation }));
              handleClose(event);
            }}
            primary={t(I18nKeys.DialogDeleteButton)}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
