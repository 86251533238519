import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AppState } from '../types/AppState';
import { useAppSelector } from '../hooks';
import { GridData } from '../types/DataGrid';
import { TableSearchResults } from './TableSearchResults';
import { DataGridAccordion } from './DataGridAccordion';
import { SearchType } from '../constants/ClientData';
import {
  getVendorFromClientId,
  mapClientIdToConfiguratorAndVendor,
  mapConfiguratorToClientId,
} from '../utils/clientIdUtils';
import { Group } from '../types/Group';
import { Configurator } from '../types/Configurator';

type Props = {
  clientId: string;
  searchData: GridData;
  expandedView: string;
  setExpandedView: (view: string) => void;
  getMaxTableHeight: () => string;
  refs: {
    search: React.RefObject<HTMLDivElement>;
    findAll: React.RefObject<HTMLDivElement>;
    rootGrid: React.RefObject<AgGridReact>;
  };
};

export const ClientSearchResults: React.FC<Props> = ({
  clientId,
  searchData,
  expandedView,
  setExpandedView,
  getMaxTableHeight,
  refs,
}) => {
  const {
    clientId: mainClientId,
    search: { type: searchType = SearchType.AllTables, result: { ids: resultIds = [] } = {} } = {},
  } = useAppSelector((state: AppState) => state?.clientData);

  const { groups = [] } = useAppSelector((state: AppState) => state?.groups);

  const [title, setTitle] = useState(clientId);
  const [hideExpand, setHideExpand] = useState(true);
  const [displayedClientId, setDisplayedClientId] = useState(clientId);
  const [groupId, setGroupId] = useState('');
  const [uniqueTables, setUniqueTables] = useState<string[]>([]);

  // Filter the search result ids to the current client
  const regex = new RegExp(`${clientId}:`);
  const filteredResultIds = resultIds.filter((id) => regex.test(id));

  useEffect(() => {
    if (!mainClientId || !clientId) return;

    // Only searching within same product right now
    const { configurator: product } = mapClientIdToConfiguratorAndVendor(mainClientId);
    const vendor = getVendorFromClientId(clientId);

    if (!product || !vendor) return;

    // Check the groups array first to find the client name
    // Check if the clientId is in the group's configurators array
    const group = groups.find((g: Group) =>
      g?.configurators?.find((c: Configurator) => c.key === product && c.vendor === vendor),
    );
    const config = group?.configurators?.find((c: Configurator) => c.key === product && c.vendor === vendor);

    if (group && config) {
      setTitle(group.groupName);
      setGroupId(group.groupId);
      const client = mapConfiguratorToClientId(config);
      setDisplayedClientId(client);
    }
  }, [clientId, mainClientId, groups.length]);

  useEffect(() => {
    setUniqueTables(
      Array.from(
        new Set(
          resultIds
            .filter((id) => id.split(':')[0] === clientId)
            .map((id) => id.split(':')[1])
            .filter(Boolean),
        ),
      ),
    );
  }, [resultIds, clientId]);

  useEffect(() => {
    setHideExpand(![SearchType.AllVendorsCurrentTable, SearchType.AllVendorsAllTables].includes(searchType));
  }, [searchType]);

  const handleExpand = (table = '') => {
    const [expandedClient, expandedTable] = expandedView.split(':');
    const newView = `${clientId}:${table}`;
    if (!table && expandedClient === clientId) {
      // Client is already expanded, collapse it
      setExpandedView('');
    } else if (!table && expandedClient !== clientId) {
      // Client is not expanded, expand it
      setExpandedView(newView);
    } else if (expandedTable === table) {
      // Table is already expanded, collapse it
      setExpandedView(`${clientId}:`);
    } else {
      // Table is not expanded, expand it
      setExpandedView(newView);
    }
  };

  return (
    <DataGridAccordion
      hideExpand={hideExpand}
      expanded={!!expandedView && expandedView.split(':')[0] === clientId}
      handleExpand={handleExpand}
      title={title}
      resultCount={filteredResultIds.length}
    >
      {uniqueTables.map((table) => (
        <TableSearchResults
          clientId={displayedClientId}
          key={table}
          table={table}
          searchData={searchData[table] || []}
          expandedView={expandedView}
          handleExpand={() => handleExpand(table)}
          getMaxTableHeight={getMaxTableHeight}
          refs={refs}
          context={{
            groupId,
          }}
        />
      ))}
    </DataGridAccordion>
  );
};
