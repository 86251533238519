import { createApi } from '@reduxjs/toolkit/query/react';
import { Integration, IntegrationData, IntegrationName, IntegrationStatus } from '@idearoom/types';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';

export const integrationApi = createApi({
  reducerPath: 'integrationApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/integration',
  }),
  endpoints: (builder) => ({
    /**
     * Gets payment integration status
     */
    getPaymentIntegration: builder.query<
      { status: IntegrationStatus; message?: string },
      {
        groupId: string;
        clientIds: string[];
      }
    >({
      query: ({ groupId, clientIds }) => ({
        url: `/payment`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId }),
          queryStringParameters: {
            clientIds: clientIds.join(','),
          },
        },
        displayToastOnError: false,
      }),
    }),

    /**
     * Post a request to start the process to create the payment integration
     */
    createPaymentIntegration: builder.query<
      { onboardingUrl?: string },
      {
        groupId: string;
        clientIds: string[];
        legalEntityName?: string;
        shopperStatement?: string;
      }
    >({
      query: ({ groupId, clientIds, legalEntityName, shopperStatement }) => ({
        url: `/payment`,
        method: 'post',
        init: {
          headers: getRequestHeader({ groupId }),
          body: {
            clientIds,
            legalEntityName,
            shopperStatement,
          },
        },
      }),
    }),

    /**
     * Gets a list of all integrations
     */
    getIntegrations: builder.query<
      Integration[],
      {
        groupId: string;
        clientId: string;
      }
    >({
      query: ({ groupId, clientId }) => ({
        url: `/`,
        method: 'get',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
    }),

    putIntegration: builder.mutation<
      Integration,
      {
        groupId: string;
        clientId: string;
        name: IntegrationName;
        data: IntegrationData;
        message?: string;
      }
    >({
      query: ({ groupId, clientId, name, data, message }) => ({
        url: `/${name}`,
        method: 'put',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
          body: {
            data,
            message,
          },
        },
      }),
      async onQueryStarted({ groupId, clientId, name, data }, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          integrationApi.util.updateQueryData('getIntegrations', { groupId, clientId }, (draft) => {
            const integration = draft.find((i) => i.name === name);
            if (integration) {
              if ('enabled' in data) {
                integration.status = data.enabled ? IntegrationStatus.Connected : IntegrationStatus.Deactivated;
              } else {
                integration.data = data;
              }
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patch.undo();
        }
      },
    }),
  }),
});

export const { useGetPaymentIntegrationQuery, useCreatePaymentIntegrationQuery, useGetIntegrationsQuery } =
  integrationApi;
