import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { PricingSheetGrid } from './PricingSheetGrid';
import { useAppSelector } from '../hooks';
import { PricingSheetRegion, PriceColumnsByGroupId } from '../types/Region';
import { AppState } from '../types/AppState';
import { PricingBaseNoteDialog } from './PricingBaseNoteDialog';
import { PricingContactSupportDialog } from './PricingContactSupportDialog';
import { getClientIdFromClientSupplier, getConfiguratorFromClientId } from '../utils/clientIdUtils';
import {
  PricesPerRegionDiff,
  getAvailablePricesPerRegionDiff,
  getSubtitleText,
  hasDefaultRegionFunc,
} from '../utils/pricingSheetUtils';
import { GridViewType } from '../constants/GridViewType';
import { PricingSheetAccordion } from './PricingSheetAccordion';
import { PricingSheet as PricingSheetType } from '../types/PricingSheet';
import { CellMetadata, ClientDataTableRowDiff } from '../types/ClientData';
import { PricingTab } from '../constants/Pricing';
import { PricingSheetHeader } from './PricingSheetHeader';
import { PricingSheetViewSelect } from './PricingSheetViewSelect';
import { Loading } from './Loading';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '24px',
    height: 'auto',
    minWidth: '75%',
    overflowY: 'auto',
  },
  accordion: {
    background: 'none',
    marginBottom: '16px',
    '&$expanded': {
      display: 'flex',
      flexDirection: 'column',
    },
    '&:before': {
      height: '0px',
    },
  },
  regionTitle: {
    margin: '0px 8px 0px 0px',
    fontWeight: '700',
    fontSize: '16px',
  },
  regionDiffs: {
    fontWeight: '400',
    fontSize: '14px',
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.60)',
  },
  accordionSummary: {
    padding: '0px',
    flexDirection: 'row-reverse',
  },
  accordionDetails: {
    padding: '0px',
    marginTop: '16px',
  },
  topContainer: {
    display: 'flex',
  },
  wrapper: {
    display: 'block',
    minWidth: 'max-content',
    minHeight: 'max-content',
  },
}));

type Props = {
  gridViewType: GridViewType;
  selectedPricingSheetId?: string;
  selectedPricingSheet?: PricingSheetType;
  isLoadingPricingSheetPrices?: boolean;
  pricingSheets: PricingSheetType[];
  regions: PricingSheetRegion[];
  defaultPriceColumn: PriceColumnsByGroupId;
  cellMetadata: CellMetadata[];
  pricingDiffs: {
    table: string;
    changes: ClientDataTableRowDiff[];
  }[];
  accordionsState: { [pricingSheetId: string]: { [index: number]: boolean } };
  onAccordionChange: (index: number) => void;
};

export const PricingSheet: React.FC<Props> = ({
  gridViewType,
  selectedPricingSheetId = '',
  selectedPricingSheet,
  isLoadingPricingSheetPrices,
  pricingSheets,
  regions,
  defaultPriceColumn,
  cellMetadata,
  pricingDiffs,
  accordionsState,
  onAccordionChange,
}: Props) => {
  const classes = useStyles();
  const [subtitleTextPerRegion, setSubtitleTextPerRegion] = React.useState<{ [rowId: string]: string }>({});
  const [availablePricePerRegion, setAvailablePricePerRegion] = React.useState<Map<string, PricesPerRegionDiff>>(
    new Map(),
  );
  const hasDefaultRegion = hasDefaultRegionFunc(regions, defaultPriceColumn);

  const viewerClientId = useAppSelector(
    (state: AppState) => state?.viewer?.selectedTabId || state?.viewer?.selectedClientId || '',
  );
  const clientId = getClientIdFromClientSupplier(viewerClientId);
  const configurator = getConfiguratorFromClientId(clientId);

  const {
    viewer: { selectedPricingTabId: pricingTab },
  } = useAppSelector((state: AppState) => state || {});

  useEffect(() => {
    const { prices } = selectedPricingSheet || {};
    if (regions.length > 0 && prices) {
      const newAvailablePricePerRegion = getAvailablePricesPerRegionDiff(
        configurator?.key,
        availablePricePerRegion,
        regions,
        defaultPriceColumn,
        prices,
      );

      let updateSubtitleText = false;
      const newSubtitleTextPerRegion: { [rowId: string]: string } = {};
      regions.forEach((r) => {
        const isHiddenRegion = !!prices?.every(({ hidden = {} }) => hidden[r.regionKey]);
        const text = getSubtitleText(newAvailablePricePerRegion, prices.length, r, defaultPriceColumn, isHiddenRegion);
        if (subtitleTextPerRegion[r.rowId] !== text) {
          updateSubtitleText = true;
        }
        newSubtitleTextPerRegion[r.rowId] = text;
      });

      if (updateSubtitleText) {
        setSubtitleTextPerRegion(newSubtitleTextPerRegion);
      }
      setAvailablePricePerRegion(newAvailablePricePerRegion);
    }
  }, [subtitleTextPerRegion, configurator, selectedPricingSheet, availablePricePerRegion, regions]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.topContainer}>
          <PricingSheetHeader
            selectedPricingSheet={selectedPricingSheet}
            pricingSheets={pricingSheets}
            regions={regions}
          />
          <PricingSheetViewSelect gridViewType={gridViewType} />
        </div>

        {isLoadingPricingSheetPrices && (
          <div style={{ height: '100%', alignContent: 'center' }}>
            <Loading />
          </div>
        )}

        {!isLoadingPricingSheetPrices && gridViewType === GridViewType.List && (
          <PricingSheetGrid
            gridViewType={gridViewType}
            regions={regions}
            defaultPriceColumn={defaultPriceColumn}
            hasDefaultRegion={hasDefaultRegion}
            pricingSheet={selectedPricingSheet}
            cellMetadata={cellMetadata}
            diffs={pricingDiffs}
            showAddSize={pricingTab === PricingTab.Base}
          />
        )}

        {!isLoadingPricingSheetPrices && regions.length === 1 && gridViewType === GridViewType.Grid && (
          <PricingSheetGrid
            gridViewType={gridViewType}
            pricingSheet={selectedPricingSheet}
            defaultPriceColumn={defaultPriceColumn}
            cellMetadata={cellMetadata}
            regions={regions}
            diffs={pricingDiffs}
            showAddSize={pricingTab === PricingTab.Base}
          />
        )}

        {!isLoadingPricingSheetPrices &&
          regions.length > 1 &&
          gridViewType === GridViewType.Grid &&
          regions.map((region, index) => (
            <PricingSheetAccordion
              key={region.regionKey}
              index={index}
              regions={regions}
              defaultPriceColumn={defaultPriceColumn}
              defaultExpanded={index === 0}
              expanded={accordionsState[selectedPricingSheetId]?.[index] || false}
              handleExpand={onAccordionChange}
              gridViewType={gridViewType}
              pricingSheet={selectedPricingSheet}
              cellMetadata={cellMetadata}
              diffs={pricingDiffs}
              subtitleTextPerRegion={subtitleTextPerRegion}
            />
          ))}
        <PricingBaseNoteDialog />
        <PricingContactSupportDialog />
      </div>
    </div>
  );
};
